import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "../users/usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSave,
    faTrashCan,
    faArrowRotateBack
} from "@fortawesome/free-solid-svg-icons"
import { useSendLogoutMutation } from '../auth/authApiSlice'
import useTitle from '../../hooks/useTitle'
import { confirmAlert } from "react-confirm-alert"
//import Enable2fa from '../auth/Enable2fa'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


// Reference color and etc.

const styles = {
    btn: {
        color: "gray",
    }
}

const USER_REGEX = /^[А-Я][а-я]{1,20}$/ // /^[A-Z][a-z]{1,20}$/
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

const EditProfileForm = ({ user }) => {
    useTitle('Edit Profile Form')

    const navigate = useNavigate()

    const [updateUser, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()


    const [sendLogout] = useSendLogoutMutation()


    const [deleteUser, {
        data: deleteData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const [showEditUserForm, setShowEditUserForm] = useState(true)

    const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 4000,
            //onClose: () => navigate('/auth/login-step2')
        })


    const [firstname, setFirstname] = useState(user.firstname)
    const [validFirstname, setValidFirstname] = useState(false)
    const [middlename, setMiddlename] = useState(user.middlename)
    const [validMiddlename, setValidMiddlename] = useState(false)
    const [surname, setSurname] = useState(user.surname)
    const [validSurname, setValidSurname] = useState(false)
    const [email, setEmail] = useState(user.email)
    const [validEmail, setValidEmail] = useState(false)

    const [password, setPassword] = useState('')

    const [newpassword, setNewPassword] = useState('')
    const [validNewPassword, setValidNewPassword] = useState(false)
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [validConfPassword, setValidConfPassword] = useState(false)

    const comparePassword = () => newpassword === confirmNewPassword

    const compareEmail = () => email === user.email
    const compareFirstname = () => firstname === user.firstname
    const compareMiddlename = () => middlename === user.middlename
    const compareSurname = () => surname === user.surname

    useEffect(() => {
        setValidFirstname(USER_REGEX.test(firstname))
    }, [firstname])

    useEffect(() => {
        setValidMiddlename(USER_REGEX.test(middlename))
    }, [middlename])

    useEffect(() => {
        setValidSurname(USER_REGEX.test(surname))
    }, [surname])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    useEffect(() => {
        setValidNewPassword(PWD_REGEX.test(newpassword))
    }, [newpassword])

    useEffect(() => {
        setValidConfPassword(PWD_REGEX.test(confirmNewPassword))
    }, [confirmNewPassword])


    //
    useEffect(() => {
        if (isSuccess) {
            navigate('/login')
            alert(data?.message)
            sendLogout()
        }
        if (isError) {
            navigate('/login')
            alert(error?.data?.message)
            sendLogout()
        }
    }, [isError || isSuccess && !compareEmail() || password || newpassword])
    //
    useEffect(() => {
        if (isDelSuccess) {
            handleInfo(deleteData)
            setTimeout(() => {
                navigate('/')
                sendLogout()
            }, 4500)
        }
        if (isDelError) {
            alert(error?.data?.message)
        }
    }, [isDelSuccess || isDelError])


    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setFirstname('')
            setMiddlename('')
            setSurname('')
            setEmail('')
            setPassword('')
            setNewPassword('')
            setConfirmNewPassword('')
            //navigate('/dash/profile')
            setShowEditUserForm(false)
        }
    }, [isSuccess, isDelSuccess])

    const onFirstnameChanged = e => setFirstname(e.target.value)
    const onMiddlenameChanged = e => setMiddlename(e.target.value)
    const onSurnameChanged = e => setSurname(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)
    const onNewPasswordChanged = e => setNewPassword(e.target.value)
    const onConfirmNewPassword = e => setConfirmNewPassword(e.target.value)

    //const canSave = [roles.length, validFirstname, validMiddlename, validSurname, validEmail, validPassword, comparePassword()].every(Boolean) && !isLoading

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (newpassword || !compareEmail()) {
            await updateUser({ id: user.id, firstname, middlename, surname, email, password, newpassword })
        } else {
            await updateUser({ id: user.id, firstname, middlename, surname })
            navigate('/dash/profile')
        }
    }

    const onDeleteUserClicked = async () => {
        confirmAlert({
            message: "Удалить Ваш Аккаунт?",
            buttons: [
                {
                    label: "Да",
                    onClick: () => deleteUser({ id: user.id })
                },
                {
                    label: "Нет"
                }
            ]
        })
    }


    let canSave
    if (newpassword) {
        canSave = [validFirstname, validMiddlename, validSurname, validEmail, validNewPassword, comparePassword()].every(Boolean) && !isLoading
    } else {
        canSave = [validFirstname, validMiddlename, validSurname, validEmail, !compareEmail() || !compareFirstname() || !compareMiddlename() || !compareSurname()].every(Boolean) && !isLoading
    }

    const errClass = isError ? "errmsg" : "offscreen"
    const validFirstnameClass = !validFirstname ? 'form__input--incomplete' : ''
    const validMiddlenameClass = !validMiddlename ? 'form__input--incomplete' : ''
    const validSurnameClass = !validSurname ? 'form__input--incomplete' : ''
    const validEmailClass = !validEmail ? 'form__input--incomplete' : ''
    const validNewPwdClass = !validNewPassword ? 'form__input--incomplete' : ''
    const validConfPwdClass = !validConfPassword ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''
    //if (errContent) {
    //    alert(errContent)
    //}

    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            {showEditUserForm && (
                <form className="form" onSubmit={e => e.preventDefault()}>
                    {/*<div className="form__title-row">
                    <h2>Auth settings</h2>
                </div>
                <Enable2fa twofaEnabled={user.twofaEnabled} />
                <div className="row m-1">
                    <div className="col form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={user.otpSmsEnabled}  disabled />
                        <label className="form-check-label" for="flexSwitchCheckDefault">Reset password with SMS</label>
                    </div>
                    <div className="col form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={user.allowSmsSending} disabled />
                        <label className="form-check-label" for="flexSwitchCheckDefault">Allow reset SMS Sending</label>
                    </div>
                </div>
                <hr className="border-2"/>*/}

                    <div className="form__title-row">
                        <h2>Edit User</h2>
                    </div>
                    <label className="form__label" htmlFor="firstname">
                        Firstname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validFirstnameClass}`}
                        id="firstname"
                        name="firstname"
                        type="text"
                        autoComplete="off"
                        value={firstname}
                        onChange={onFirstnameChanged}
                    />

                    <label className="form__label" htmlFor="middlename">
                        Middlename: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validMiddlenameClass}`}
                        id="middlename"
                        name="middlename"
                        type="text"
                        autoComplete="off"
                        value={middlename}
                        onChange={onMiddlenameChanged}
                    />

                    <label className="form__label" htmlFor="surname">
                        Surname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validSurnameClass}`}
                        id="surname"
                        name="surname"
                        type="text"
                        autoComplete="off"
                        value={surname}
                        onChange={onSurnameChanged}
                    />

                    <label className="form__label" htmlFor="email">
                        Your e-mail: <span className="nowrap">[e-mail format only]</span></label>
                    <input
                        className={`form__input ${validEmailClass}`}
                        id="email"
                        name="email"
                        type="text"
                        autoComplete="off"
                        value={email}
                        onChange={onEmailChanged}
                    />

                    <label className="form__label" htmlFor="password">
                        Your current Password:</label>
                    <input
                        className="form__input"
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={onPasswordChanged}
                    />

                    <label className="form__label" htmlFor="newpassword">
                        New Password: <span className="nowrap">[min 8 chars incl. uppercase, digit and #?!@$%^&*-]</span></label>
                    <input
                        className={`form__input ${validNewPwdClass}`}
                        id="newpassword"
                        name="newpassword"
                        type="password"
                        value={newpassword}
                        onChange={onNewPasswordChanged}
                    />

                    <label className="form__label" htmlFor="confirmNewPassword">
                        Confirm New Password:</label>
                    <input
                        className={`form__input ${validConfPwdClass}`}
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        type="password"
                        value={confirmNewPassword}
                        onChange={onConfirmNewPassword}
                    />
                    {confirmNewPassword && (
                        <p
                            className='validation-message'
                            style={{ color: comparePassword() ? "green" : "red" }}
                        >
                            {comparePassword() ? "Password Match" : "Password Does not Match"}
                        </p>
                    )}

                    <div className="row text-center">
                        <div className="col">
                            <button
                                className="icon-button"
                                title="Go back"
                                onClick={() => navigate(-1)}
                            >
                                Go back&nbsp;
                                <FontAwesomeIcon icon={faArrowRotateBack} />
                            </button>
                        </div>
                        <div className="col">
                            <button
                                className="icon-button"
                                style={!canSave ? (styles.btn) : {}}
                                title="Save"
                                onClick={onSaveUserClicked}
                                disabled={!canSave}
                            >
                                Save&nbsp;
                                <FontAwesomeIcon icon={faSave} />
                            </button>
                        </div>
                    </div>

                    <div className="row text-center">
                        <button
                            className="icon-button faTrashCan"
                            title="Delete"
                            onClick={onDeleteUserClicked}
                        >
                            Delete account&nbsp;
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                </form>
            )}
            <ToastContainer />
        </>
    )

    return content
}
export default EditProfileForm
import { useEffect, useState } from "react"
import PulseLoader from 'react-spinners/PulseLoader'
import { useGetUsersQuery } from '../users/usersApiSlice'
import Enable2fa from '../auth/Enable2fa'
import EnableSms from '../auth/EnableSms'
import useAuth from "../../hooks/useAuth"
import useTitle from '../../hooks/useTitle'
//import { useSelector, useDispatch } from 'react-redux'
//import { setOtpSms } from './authSettsSlice'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const AuthSettings = () => {
    useTitle('Authentication Settings')

    //const dispatch = useDispatch()

    //const { authsettings } = useSelector((state) => state.authsettings)


    const { userId, status } = useAuth()

    const strictRoles = ['Owner', 'Admin', 'Manager']
    const strictUser = strictRoles.some(role => status.includes(role))

    const { user, isLoading } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handleInfo = () =>
        toast.info("Turn On TOTP!", {
            position: "top-center",
            transition: Slide,
            autoClose: 1500
        })

    const [isShow, setIsShow] = useState()

    useEffect(() => {
        if (!user?.twofaEnabled && strictUser) {
            handleInfo()
            let timer = setTimeout(() => {
                setIsShow(true)
            }, 2500)

            return () => { clearTimeout(timer) }
        } else (
            setIsShow(true)
        )
    }, [])

    /*if (!user?.twofaEnabled && strictUser) {
        let timer = setTimeout(() => {
            setIsShow(false)
          }, 2000)
        return () => { clearTimeout(timer) }
    } else {
        //setIsShow(true)
    }



    /*useEffect(() => {
        handleInfo()
        if (!user?.twofaEnabled && strictUser) {
            let timer = setTimeout(() => {
                setLoading(false)
            }, 2500)
            return () => { clearTimeout(timer) }
        }
    }, [])*/



    //dispatch(setOtpSmsEnabled([{ userId: String(userId), otpSmsEnabled: Number(e.target.value) }]))    

    /*const selectAuthSettsById = (state, userid) => {
        return state.otpSmsEnabled.filter(record => record._id === userid).map(item => item.otpSmsEnabled)
    }
    const authsettings = useSelector(state => selectAuthSettsById(state, userId))*/

    //const { authsettings } = useSelector((state) => state.authsettings)

    //const [otpSmsEnabled, setOtpSmsEnabled] = useState(user?.otpSmsEnabled)

    /*useEffect(() => {
        if (isSuccess) {
            //const { authsettings } = useSelector((state) => state.authsettings, user.otpSmsEnabled)
            dispatch(setOtpSmsEnabled(user.otpSmsEnabled))
        }
    }, [isSuccess])*/

    /*const disable2FaSuccess = async (isDisable2FaSuccess) => {
        //dispatch(setOtpSmsEnabled(isDisable2FaSuccess))
        setOtpSmsEnabled(isDisable2FaSuccess)
    }

    const verifyOtpSuccess = async (isVerifyOtpSuccess) => {
        dispatch(setOtpSmsEnabled(!isVerifyOtpSuccess))
    }*/

    if (!user || isLoading) return <PulseLoader color={"#FFF"} />

    return (
        <>
            {isShow ?
                <form className="form">
                    <div className="form__title-row">
                        <h2>Authentication Settings</h2>
                    </div>
                    <EnableSms otpSmsEnabled={user.otpSmsEnabled} allowSmsSending={user.allowSmsSending} />
                    <Enable2fa twofaEnabled={user.twofaEnabled} />
                    {/*            <div className="row m-1">
                <div className="col form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={otpSmsEnabled} disabled />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Reset password with SMS</label>
                </div>
                <div className="col form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={user.allowSmsSending} disabled />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Allow reset SMS Sending</label>
                </div>
    </div>*/}
                    <hr className="border-2" />
                </form>
                : null}
            <ToastContainer />
        </>
    )
}

export default AuthSettings
import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const RequireAuth = ({ allowedRoles }) => {
    const strictRoles = ['Owner', 'Admin', 'Manager']
    const location = useLocation()
    const { roles, twofa } = useAuth()

    let content
    // && allowedRoles.some(role => strictRoles.includes(role))
    if (twofa) {
        content = (
            roles.some(role => allowedRoles.includes(role))
                ? <Outlet />
                : <Navigate to="/login" state={{ from: location }} replace />
        )
    } else {
        content = (
            <Navigate to="/dash/profile/auth" state={{ from: location }} replace />
        )
    }

    return content
}
export default RequireAuth
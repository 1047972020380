import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginStep2Mutation } from './authApiSlice'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import useAuth from "../../hooks/useAuth"
import OtpInput from "./OtpInput"
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const LoginStep2 = () => {
    useTitle('Step Two Login')

    const { email } = useAuth()

    //const errRef = useRef()

    //const [otp, setOtp] = useState('')

    //const [errMsg, setErrMsg] = useState('')
    const [showOtpDialog, setShowOtpDialog] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loginStep2, { isLoading }] = useLoginStep2Mutation()

    /*useEffect(() => {
        if (isSuccess) {
            //setOtp('')
            //navigate('/dash')
        }
    }, [isSuccess])*/

    const handleError = (err) =>
        toast.error(err, {
            position: "top-center",
            transition: Slide,
            autoClose: 3000
        })
    const handleSuccess = () =>
        toast.success("Welcome!", {
            position: "top-center",
            transition: Slide,
            autoClose: 1000,
            onClose: () => navigate('/dash')
        })

    const onOtpSubmit = async (otp) => {
        //e.preventDefault()
        try {
            const { accessToken } = await loginStep2({ email, otp }).unwrap()
            dispatch(setCredentials({ accessToken }))
            handleSuccess()
            setShowOtpDialog(false)
            //setTimeout(() => {
            //    navigate('/dash')
            //}, 1500)
            //navigate('/dash')
            /*axios
            .post(
              "http://localhost:3500/auth/login-step2",
              {
                email: email,
                twofaToken: otp,
              },
              {}
            )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setCredentials( response.accessToken ))
                  navigate("/dash")
                  alert(response.accessToken)
                }
              })*/

            /*if (!twofaEnabled) {
                navigate('/dash')
                alert(message)
            } else {
                //setTwofa(twofaEnabled)
                navigate('/login-step2')
                alert(message)
            }*/
        } catch (err) {
            if (!err.status) {
                handleError('No Server Response')
                //setErrMsg('No Server Response')
            } else if (err.status === 400) {
                {/*setErrMsg('Missing Email or Password')*/ }
                //alert(err?.data?.message)
                handleError(err.data?.message)
                //navigate('/login')
            } else if (err.status === 401) {
                {/*setErrMsg(err?.data?.message)*/ }
                //alert(err?.data?.message)
                handleError(err.data?.message)
                //navigate('/login')
            } else {
                {/*setErrMsg(err.data?.message)*/ }
                //alert(err.data?.message)
                handleError(err.data?.message)
                //navigate('/login')
            }
            //errRef.current.focus();
        }
    }

    //const handleUserInput = (e) => setOtp(e.target.value)

    //const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <section className="public">
            <header>
                <h1>Step Two Login</h1>
            </header>
            {showOtpDialog && (
                <main className="login">
                    <p className="text-center">To keep your account secure, we verify your identity.<br />
                        Enter the <u>code</u> generated by your <u>Authenticator app</u>.<br /><pre />
                        Account:<br />
                        <strong>
                            {email}
                        </strong>
                    </p>
                    <span className="text-center">Enter Verification Code:</span>
                    <span className="text-center">
                        <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
                    </span>
                    {/*<p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>*/}
                </main>
            )}
            <footer>
                {showOtpDialog && (<Link to="/">Back to Home</Link>)}
            </footer>
            <ToastContainer />
        </section>
    )

    return content
}
export default LoginStep2
import React, { useState, useEffect } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useUpdateMaterialMutation, useDeleteMaterialMutation } from "../materialsApiSlice"
import { useSelector, shallowEqual  } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setPrice } from '../PriceSlice'
import useAuth from "../../../hooks/useAuth"


const SortableItem = ({ material }) => {

  const { isManager, isAdmin } = useAuth()

  const dispatch = useDispatch()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: material._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [updateMaterial, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useUpdateMaterialMutation()

  const [deleteMaterial, {
    isSuccess: isDelSuccess,
    isError: isDelError,
    error: delerror
  }] = useDeleteMaterialMutation()

  //console.dir("ffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"+inc)
  //console.dir(inc)

  const selectTodoById = (state, todoId) => {
    return state.materials.filter(record => record.item_id === todoId).map(item => item.count)
  }
  const record = useSelector(state => selectTodoById(state, material._id),shallowEqual)

  const selectPriceById = (state, todoId) => {
    return state.price.filter(record => record.item_id === todoId).map(item => item.price)
  }
  const price_reduxStore = useSelector(state => selectPriceById(state, material._id),shallowEqual)
  
  /*console.dir("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR"+record)
  console.dir(record)
  console.dir("WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW"+price_reduxStore)
  console.dir(price_reduxStore)*/

  const [item, setItem] = useState(material.item)
  const [price, setPriceLocalState] = useState(material.price || "")
  const [measure, setMeasure] = useState(material.measure)
  const [in_stock, setIn_stock] = useState(material.in_stock || "")

  //const onItemChanged = e => setItem(e.target.value)
  //const onPriceChanged = e => setPrice(e.target.value)
  //const onMeasureChanged = e => setMeasure(e.target.value)
  //const onIn_stockChanged = e => setIn_stock(e.target.value)
  //const result = Number(incrm.map(a => a.count))

  

  useEffect(() => {    
    if(record?.length) {
      //const result = Number(record)
      setIn_stock(Number(record))
    }
  }, [record]);

  useEffect(() => {  
    if(price_reduxStore?.length) {
      //const result = Number(record)
      setPriceLocalState(Number(price_reduxStore))
    }
  }, [price_reduxStore]);


  const updateItem = async (e) => {
    setItem(e.target.value);
    await updateMaterial({ id: material._id, item: e.target.value })
    //console.log(e.target.value)
    //console.log(material._id)
    //props.onEditMaterialItem({
    //  item_id: props.item_id,
    //  item: e.target.value,
    //price: price
    //});
  }

  const updatePrice = async (e) => {
    setPriceLocalState(e.target.value);
    await updateMaterial({ id: material._id, price: e.target.value })
    dispatch(setPrice([{item_id: material._id, price: e.target.value}]))
    //props.onEditMaterial({
    //  item_id: props.item_id,
    //item: item,
    //  price: e.target.value
    //});
  };

  const updateMeasure = async (e) => {
    setMeasure(e.target.value);
    await updateMaterial({ id: material._id, measure: e.target.value })
    //props.onEditMaterialMeasure({
    //  item_id: props.item_id,
    //  //item: item,
    //  measure: e.target.value
    //});
  }

  const updateInStock = async (e) => {
    setIn_stock(e.target.value);
    await updateMaterial({ id: material._id, in_stock: e.target.value })
    //props.onEditMaterialStock({
    //  item_id: props.item_id,
    //item: item,
    //  in_stock: e.target.value
    //});
  }

  const onDeleteMaterialClick = async () => {
    //const handleTrashClickMaterialItem = () => {
    //props.onTrashClickMaterialItem(props.item_id);
    await deleteMaterial({ id: material.id })
  }

  const disabled = !(isManager || isAdmin) ? true  : false

  return (
    <tr ref={setNodeRef} style={style}>
      <td className="align-middle text-left" colSpan="3">
        <span {...attributes} {...listeners}
          className="move_pointer"
          aria-hidden="true"
          role="img"
          hidden={disabled}
        >&#x2630;</span>&nbsp;
        <input className="material_item" value={item} onChange={!disabled ? updateItem : ""} disabled={disabled}/>
      </td>
      <td className="align-middle">
        <input
          className="material_item text-center"
          value={measure}
          onChange={!disabled ? updateMeasure : ""}
          disabled={disabled}
        />
      </td>
      <td className="">
        <input
          className="material_price text-center"
          value={in_stock}
          onChange={!disabled ? updateInStock : ""}
          disabled={disabled}
        />
      </td>
      <td className={`justify-content-between ${disabled ? '' : 'd-flex'}`}>
        <input
          className="material_price"
          value={price || ''}
          onChange={!disabled ? updatePrice : ""}
          disabled={disabled}
        />
        <span
          className="pointer"
          onClick={!disabled ? onDeleteMaterialClick : ""}
          aria-hidden="true"
          role="img"
          aria-label="x"
          hidden={disabled}
        >&#x274C;</span>
      </td>
    </tr>
  )
}

export default SortableItem
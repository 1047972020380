import { useRef, useState, useEffect } from 'react'
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useForgotpasswordMutation } from './authApiSlice'
//import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import { useNavigate } from 'react-router-dom'
import { /*ToastContainer,*/ toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const ForgotPassword = () => {
    //useTitle('Kvitok App')

    const navigate = useNavigate()

    const styles = {
        pointer: {
            cursor: "pointer",
        }
    }

    const userRef = useRef()
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [showForgotPassword, setForgotPassword] = useState(false)

    useEffect(() => {
        if (showForgotPassword) {
            userRef.current.focus()
        }
    }, [showForgotPassword])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    const [forgotpassword, {
        //data,
        isLoading,
        //isSuccess,
        //isError,
        //error
    }] = useForgotpasswordMutation()

    const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 4500,
            onClose: () => navigate('/')
        })

    const handleError = (err) =>
        toast.error(err, {
            position: "top-center",
            transition: Slide,
            autoClose: 4000,
            onClose: () => navigate('/')
        })

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    /*useEffect(() => {
        if (isSuccess) {
            setEmail('')
            alert(data?.message)
            setForgotPassword(false)
            navigate('/')
        }
        if (isError) {
            alert(error?.data?.message)
            setEmail('')
            setForgotPassword(false)
        }
    }, [isSuccess || isError])*/

    const handleVerifyClick = async (e) => {
        e.preventDefault()
        if (email) {
            try {
                const { message } = await forgotpassword({ email }).unwrap()
                setEmail('')
                setForgotPassword(false)
                handleInfo(message)
                //setTimeout(() => {
                //    navigate('/')
                //}, 5000)
            } catch (err) {
                if (!err.status) {
                    handleError('No Server Response')
                } else if (err.status === 400) {
                    handleError(err.data?.message)
                } else if (err.status === 401) {
                    handleError(err.data?.message)
                } else {
                    handleError(err.data?.message)
                }
                setEmail('')
                setForgotPassword(false)
                //setTimeout(() => {
                //    navigate('/')
                //}, 4500)
            }
        }
    }

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const canSave = [validEmail].every(Boolean) && !isLoading

    const content = (
        <>
            <a style={styles.pointer} onClick={() => setForgotPassword(true)}>Forgot password?</a>

            <Modal
                show={showForgotPassword}
                onHide={() => setForgotPassword(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <p>To restore password:&nbsp;<b>enter Your email</b></p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col">
                            <p className="d-flex">
                                Next follow the message sent to Your e-mail box.
                            </p>
                            <input
                                type="email"
                                ref={userRef}
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Enter your email"
                                autoComplete="off"
                                required
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col text-start">
                        <Button variant="secondary" onClick={() => setForgotPassword(false)}>
                            Close
                        </Button>
                    </div>
                    <div className="col text-end">
                        <Button variant="success" onClick={handleVerifyClick} disabled={!canSave}>
                            Send request
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/*<ToastContainer />*/}
        </>
    )

    return content
}

export default ForgotPassword
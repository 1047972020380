import { useGetMaterialsQuery } from "./materialsApiSlice"
import PulseLoader from 'react-spinners/PulseLoader'
import Dnd from "./dnd-kit/Dnd.js"

const MsList = () => {

  const {
    data: materials,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetMaterialsQuery('materialsList', {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  let content

  if (isLoading) content = <PulseLoader color={"#FFF"} />

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    //console.dir("IT IS OBJECT" + materials)

    const { ids, entities } = materials

    const filteredIds = [...ids]

    const filteredMaterials = filteredIds.map(id => materials?.entities[id])
    //data.sort((a,b) => a.index - b.index); // b - a for reverse sort

    content = (
      <>
        <Dnd materials={filteredMaterials} />
      </>
    )
    return content
  }
}
export default MsList

import { useState, useEffect } from "react"
import { useSelector/*, useDispatch*/ } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { setOtpSms } from '../settings/authSettsSlice'


const EnableSms = ({ otpSmsEnabled, allowSmsSending }) => {

  //const dispatch = useDispatch()

  const [switchOtpSms, setSwitchOtpSms] = useState(otpSmsEnabled)
  const [allowSmsState, setAllowSmsSend] = useState(allowSmsSending)

  const { otpSmsState } = useSelector(state => (state.authsettings))
  /*console.dir(otpSmsState + "otpSmsState")
  console.dir(otpSmsEnabled + "otpSmsEnabled")
  console.dir(switchOtpSms + "switchOtpSms")*/

  //const otpSmsState = useSelector(state => ((state.otpSms), otpSmsEnabled))

  useEffect(() => {
    if (typeof otpSmsState === "boolean") { //otpSmsState !== ""
      setSwitchOtpSms(otpSmsState)
    }
  }, [otpSmsState])

  useEffect(() => {
    setSwitchOtpSms(otpSmsEnabled)
  }, [otpSmsEnabled])

  useEffect(() => {
    setAllowSmsSend(allowSmsSending)
  }, [allowSmsSending])

  /*useEffect(() => {
    setSwitchOtpSms(aretsertwser)
  }, [aretsertwser])*/

  /*const loadDetailHandler = async () => {
    dispatch(setOtpSms(true))
    console.dir(otpSmsState + " otpSmsState")
  };*/

  return (
    <>
      <div className="row m-1">
        <div className="col form-check form-switch">
          <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={switchOtpSms} disabled />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Reset password with SMS</label>
        </div>
        <div className="col form-check form-switch">
          <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={allowSmsState} disabled />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Allow reset SMS Sending</label>
        </div>
      </div>
      {/*<hr className="border-2" />*/}
      {/*<div onClick={loadDetailHandler}>Click me</div >*/}
    </>
  )
}

export default EnableSms
import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ROLES } from "../../config/roles"
import useAuth from "../../hooks/useAuth"
import {
    faSave,
    faTrashCan,
    faArrowRotateBack
} from "@fortawesome/free-solid-svg-icons"
import useTitle from '../../hooks/useTitle'
import { confirmAlert } from "react-confirm-alert"
//import { ToastContainer, toast, Slide } from 'react-toastify'
//import 'react-toastify/dist/ReactToastify.css'

const styles = {
    btn: {
        color: "gray",
    }
}

const USER_REGEX = /^[А-Я][а-я]{1,20}$/ // /^[A-Z][a-z]{1,20}$/
const EMAIL_REGEX = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/ //      ^$|pattern      in the beginning allows empty string
//const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

const EditUserForm = ({ user }) => {
    useTitle('Edit User Form')

    const navigate = useNavigate()

    const { status } = useAuth()

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        data: deleteData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const [showEditUserForm, setShowEditUserForm] = useState(true)

    /*const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 4000,
            //onClose: () => navigate('/auth/login-step2')
        })*/

    const [firstname, setFirstname] = useState(user.firstname)
    const [validFirstname, setValidFirstname] = useState(false)
    const [middlename, setMiddlename] = useState(user.middlename)
    const [validMiddlename, setValidMiddlename] = useState(false)
    const [surname, setSurname] = useState(user.surname)
    const [validSurname, setValidSurname] = useState(false)

    //const [email, setEmail] = useState(user.email)
    //const [validEmail, setValidEmail] = useState(false)
    const [email_alter, setEmailAlter] = useState(user.email_alter ? user.email_alter : "")
    const [validEmailAlter, setValidEmailAlter] = useState(false)
    //const [username, setUsername] = useState(user.username)
    //const [validUsername, setValidUsername] = useState(false)
    //const [password, setPassword] = useState('')
    //const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(user.roles)
    const [active, setActive] = useState(user.active)
    const [otpSms, setOtpSms] = useState(user.otpSmsEnabled)
    const [allowOtpSms, setAllowOtpSms] = useState(user.allowSmsSending)


    const compareEmailAlterToOld = () => email_alter === user.email
    const compareEmailAlterToPrevAlter = () => email_alter === (user.email_alter ? user.email_alter : "") //user.email_alter ? email_alter === user.email_alter : ""//user.email_alter ? email_alter === user.email_alter : "" probably there is better way to declare such task?
    const compareFirstname = () => firstname === user.firstname
    const compareMiddlename = () => middlename === user.middlename
    const compareSurname = () => surname === user.surname
    const compareActive = () => active === user.active
    const compareOtpSms = () => otpSms === user.otpSmsEnabled
    const compareAllowOtpSms = () => allowOtpSms === user.allowSmsSending
    const compareRoles = () => roles.toString() === user.roles.toString()


    useEffect(() => {
        setValidFirstname(USER_REGEX.test(firstname))
    }, [firstname])

    useEffect(() => {
        setValidMiddlename(USER_REGEX.test(middlename))
    }, [middlename])

    useEffect(() => {
        setValidSurname(USER_REGEX.test(surname))
    }, [surname])

    //useEffect(() => {
    //    setValidEmail(EMAIL_REGEX.test(email))
    //}, [email])

    useEffect(() => {
        setValidEmailAlter(EMAIL_REGEX.test(email_alter))
    }, [email_alter])

    //useEffect(() => {
    ///    setValidUsername(USER_REGEX.test(username))
    //}, [username])

    //useEffect(() => {
    //    setValidPassword(PWD_REGEX.test(password))
    //}, [password])

    useEffect(() => {
        if (isDelSuccess) {
            //handleInfo(deleteData)
            //setTimeout(() => {
                navigate('/dash/users')
            //}, 4500)
        }
        if (isDelError) {
            alert(error?.data?.message)
        }
    }, [isDelSuccess || isDelError])

    useEffect(() => {
        //console.log(isSuccess)
        if (isDelSuccess) {
            setFirstname('')
            setMiddlename('')
            setSurname('')
            setEmailAlter('')
            setRoles([])
            setActive('')
            setOtpSms('')
            setAllowOtpSms('')
            setShowEditUserForm(false)
        }

    }, [isDelSuccess, navigate])

    //const onUsernameChanged = e => setUsername(e.target.value)
    const onFirstnameChanged = e => setFirstname(e.target.value)
    const onMiddlenameChanged = e => setMiddlename(e.target.value)
    const onSurnameChanged = e => setSurname(e.target.value)
    //const onEmailChanged = e => setEmail(e.target.value)
    const onEmailAlterChanged = e => setEmailAlter(e.target.value)
    //const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onActiveChanged = () => setActive(prev => !prev)
    const onOtpSmsChanged = (event) => {
        setOtpSms(event.target.checked)
        !event.target.checked && setAllowOtpSms(false)
    }
    const onAllowOtpSmsChanged = () => setAllowOtpSms(prev => !prev)

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (email_alter) {
            await updateUser({ id: user.id, firstname, middlename, surname, email_alter, roles, active, otpSms, allowOtpSms })
        } else {
            await updateUser({ id: user.id, firstname, middlename, surname, roles, active, otpSms, allowOtpSms })
        }
    }

    const onDeleteUserClicked = () => {
        confirmAlert({
            message: "Удалить Пользователя?",
            buttons: [
                {
                    label: "Да",
                    onClick: () => deleteUser({ id: user.id })
                },
                {
                    label: "Нет"
                }
            ]
        })
    }

    const newArray = Object.keys(ROLES).map((key) => ROLES[key])

    /*console.log(newArray)
    console.log("newArraynewArray")*/

    const allowedRoles = newArray.filter(x => newArray.indexOf(x) < newArray.indexOf(status))

    /*console.log(allowedRoles)
    console.log("statusRoles")*/

    const options = allowedRoles.map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    let canSave
    if (email_alter && !compareEmailAlterToOld() && !compareEmailAlterToPrevAlter()) {
        canSave = [validFirstname, validMiddlename, validSurname, roles.length, validEmailAlter].every(Boolean) && !isLoading
    } else if (email_alter && compareEmailAlterToPrevAlter()) {
        canSave = [validFirstname, validMiddlename, validSurname, roles.length, !compareFirstname() || !compareMiddlename() || !compareSurname() || !compareRoles() || !compareActive() || !compareOtpSms() || !compareAllowOtpSms()].every(Boolean) && !isLoading
    } else if (!email_alter) {
        canSave = [validFirstname, validMiddlename, validSurname, roles.length, !compareFirstname() || !compareMiddlename() || !compareSurname() || !compareRoles() || !compareActive() || !compareOtpSms() || !compareAllowOtpSms() || !compareEmailAlterToPrevAlter()].every(Boolean) && !isLoading
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validFirstnameClass = !validFirstname ? 'form__input--incomplete' : ''
    const validMiddlenameClass = !validMiddlename ? 'form__input--incomplete' : ''
    const validSurnameClass = !validSurname ? 'form__input--incomplete' : ''
    //const validEmailClass = !validEmail ? 'form__input--incomplete' : ''
    const validEmailAlterClass = !validEmailAlter || compareEmailAlterToOld() ? 'form__input--incomplete' : ''
    //const validUserClass = !validUsername ? 'form__input--incomplete' : ''
    //const validPwdClass = password && !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            {showEditUserForm && (
                <form className="form" onSubmit={e => e.preventDefault()}>

                    <div className="form__title-row">
                        <h2>Edit User credentials form</h2>
                    </div>

                    <label className="form__label" htmlFor="firstname">
                        Firstname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validFirstnameClass}`}
                        id="firstname"
                        name="firstname"
                        type="text"
                        autoComplete="off"
                        value={firstname}
                        onChange={onFirstnameChanged}
                    />

                    <label className="form__label" htmlFor="middlename">
                        Middlename: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validMiddlenameClass}`}
                        id="middlename"
                        name="middlename"
                        type="text"
                        autoComplete="off"
                        value={middlename}
                        onChange={onMiddlenameChanged}
                    />

                    <label className="form__label" htmlFor="surname">
                        Surname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                    <input
                        className={`form__input ${validSurnameClass}`}
                        id="surname"
                        name="surname"
                        type="text"
                        autoComplete="off"
                        value={surname}
                        onChange={onSurnameChanged}
                    />

                    <label className="form__label" htmlFor="email">
                        User current e-mail: <span className="nowrap">[e-mail format only]</span></label>
                    <input
                        className={`form__input`}
                        id="email"
                        type="text"
                        autoComplete="off"
                        value={user.email}
                        disabled
                    />

                    <label className="form__label" htmlFor="email_alter">
                        User e-mail for change: <span className="nowrap">[e-mail format only]
                        </span>
                    </label>
                    {compareEmailAlterToOld() && (
                        <span
                            className='validation-message'
                            style={{ color: "red" }}
                        >The aim is to change current e-mail if needed
                        </span>
                    )}
                    <input
                        className={`form__input ${validEmailAlterClass}`}
                        id="email_alter"
                        name="email_alter"
                        type="text"
                        autoComplete="off"
                        value={email_alter}
                        onChange={onEmailAlterChanged}
                    />

                    <div className="row ms-1">
                        <label className="form__label" htmlFor="roles">
                            ASSIGNED ROLES:</label>
                        <select
                            id="roles"
                            name="roles"
                            className={`form__select ${validRolesClass}`}
                            multiple={true}
                            size="3"
                            value={roles}
                            onChange={onRolesChanged}
                        >
                            {options}
                        </select>

                        <label className="form__label form__checkbox-container" htmlFor="user-active"></label>
                        ACTIVE:&nbsp;
                        <input
                            className="form__checkbox"
                            id="user-active"
                            name="user-active"
                            type="checkbox"
                            checked={active}
                            onChange={onActiveChanged}
                        />
                    </div>

                    <div className="form-check form-switch m-1">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={user.twofaEnabled} disabled />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">TOTP 2FA</label>
                    </div>
                    <div className="row m-1">
                        <div className="col form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={otpSms} onChange={onOtpSmsChanged} disabled={user.twofaEnabled} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Reset password with SMS</label>
                        </div>
                        <div className="col form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={allowOtpSms} onChange={onAllowOtpSmsChanged} disabled={!otpSms || user.twofaEnabled} />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Allow reset SMS Sending</label>
                        </div>
                    </div>

                    <hr className="border-2" />

                    <div className="row text-center">
                        <div className="col">
                            <button
                                className="icon-button"
                                title="Go back"
                                onClick={() => navigate(-1)}
                            >
                                Go back&nbsp;
                                <FontAwesomeIcon icon={faArrowRotateBack} />
                            </button>
                        </div>
                        <div className="col">
                            <button
                                className="icon-button"
                                style={!canSave ? (styles.btn) : {}}
                                title="Save"
                                onClick={canSave ? onSaveUserClicked : (e) => e.preventDefault()}
                                disabled={!canSave}
                            >
                                Save&nbsp;
                                <FontAwesomeIcon icon={faSave} />
                            </button>
                        </div>
                    </div>
                    <div className="row text-center">
                        <button
                            className="icon-button faTrashCan"
                            title="Delete"
                            onClick={onDeleteUserClicked}
                        >
                            Delete account&nbsp;
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                </form>
            )}
           { /*<ToastContainer />*/}
        </>
    )

    return content
}
export default EditUserForm
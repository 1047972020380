import { useState, useEffect } from "react"
import { useGetUsersQuery } from "./usersApiSlice"
//import User from './User'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import TableBody from "./TableBody"
import TableHead from "./TableHead"
//import SearchBar from "./SearchBar"
import './index.css'
import { ROLES } from "../../config/roles"
import useAuth from "../../hooks/useAuth"

const UsersList = () => {
    useTitle('Users List')

    const { status } = useAuth()

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery("usersList", {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [tableData, setTableData] = useState([])
    const [searchResults, setSearchResults] = useState([])

    let users

    if (isLoading) users = <PulseLoader color={"#FFF"} />

    useEffect(() => {
        if (isSuccess) {
            setTableData(users)
            setSearchResults(users)
        }
    }, [data, isSuccess])

    const columns = [
        { label: "User", accessor: "surname", sortable: true },
        { label: "Email", accessor: "email" },
        { label: "Roles", accessor: "roles", sortable: true }
    ]

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...searchResults].sort((a, b) => {
                if (a[sortField] === null) return 1
                if (b[sortField] === null) return -1
                if (a[sortField] === null && b[sortField] === null) return 0
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                )
            })
            setSearchResults(sorted)
        }
    }

    let content

    if (isSuccess) {

        const { ids, entities } = data

        const filteredIds = [...ids]



        const newArray = Object.keys(ROLES).map((key) => ROLES[key])
        //console.log(newArray)
        //console.log("newArraynewArray")

        //const allowedRoles = newArray.filter(x => newArray.indexOf(x) < newArray.indexOf(status))

        /*
        console.log(allowedRoles)
        console.log(newArray.indexOf(status))
        console.log(status)
        console.log("statusRoles")
        */

        users = filteredIds.map(id => data?.entities[id])
            .filter(user => Math.max(...user.roles.map(role => newArray.indexOf(role))) < newArray.indexOf(status))


        //.map(user => console.log(Math.max(...user.roles.map(role => newArray.indexOf(role)))) < newArray.indexOf(status))
        //.map(user => console.log(newArray.indexOf(user.roles.toString())) < newArray.indexOf(status))
        //.filter(user => console.log("234" + user.roles))

        //console.log(Math.max(...array1));
        // Expected output: 3

        //.filter(user => user.roles.some(role => allowedRoles.includes(role)))
        //.filter(user => Object.keys(user.roles).map((key) => user.roles[key]).filter(x => newArray.indexOf(x) < newArray.indexOf(status)))

        /*console.log("usersm_rowusers")
        console.log(users)*/

        content = (
            <>
                <table className="table table-sm table-bordered table-striped border-default table-hover m-0">
                    <TableHead columns={columns} handleSorting={handleSorting} tableData={tableData} setSearchResults={setSearchResults} />
                    <TableBody columns={columns} tableData={searchResults} />
                </table>
                {/*<SearchBar tableData={tableData} setSearchResults={setSearchResults} />*/}
            </>
        )
    }

    return content
}
export default UsersList
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'

const TableBody = ({ tableData, columns }) => {

    const navigate = useNavigate()

    return (
        <tbody>
            {tableData?.map((data) => {

                const handleEdit = () => navigate(`/dash/users/${data._id}`)

                const cellStatus = data.active ? '' : 'table__cell--inactive'
                
                return (
                    <tr key={data._id} className={`${cellStatus}`}>
                        {columns.map(({ accessor }) => {
                            const tData = data[accessor] ? data[accessor] : "——"
                            return <td key={accessor}>{tData}</td>
                        })}
                        <td>
                            <button
                                className="icon-button table__button"
                                onClick={handleEdit}
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                        </td>
                    </tr>
                )
            })}
        </tbody>
    )
}

export default TableBody
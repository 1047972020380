import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login'
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'
import NotesList from './features/notes/NotesList'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
import EditNote from './features/notes/EditNote'
import NewNote from './features/notes/NewNote'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import Invoice from './features/invoice/Invoice'
import Profile from './features/settings/Profile'
import EditProfile from './features/settings/EditProfile'
import ResetPassword from './features/auth/ResetPassword'
import ResetPwdOtpSms from './features/auth/ResetPwdOtpSms'
import ResetPwdOtp from './features/auth/ResetPwdOtp'
import Require2faJWT from './features/auth/Require2faJWT'
import LoginStep2 from './features/auth/LoginStep2'
import AuthSettings from './features/settings/AuthSettings'

function App() {
  //useTitle('Kvitok App')

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />
        <Route path="auth/resetpassword/:resetToken" element={<ResetPassword />} />
        <Route path="auth/resetpwdotpsms/:resetToken" element={<ResetPwdOtpSms />} />
        <Route path="auth/resetpwdotp/:resetToken" element={<ResetPwdOtp />} />

        <Route path="auth/login-step2" element={<Require2faJWT element={<LoginStep2 />} />}/>

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          {/*<Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>*/}
            <Route element={<Prefetch />}>
              <Route path="dash" element={<DashLayout />}>

                <Route index element={<Welcome />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin, ROLES.Owner]} />}>
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewUserForm />} />
                  </Route>
                </Route>

                <Route path="notes">
                  <Route index element={<NotesList />} />
                  <Route path=":id" element={<EditNote />} />
                  <Route path="new" element={<NewNote />} />
                </Route>

                {/*<Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>*/}
                  <Route path="invoices">
                    <Route index element={<Invoice />} />
                  </Route>
                {/*</Route>*/}

                {/*<Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>*/}
                  <Route path="profile">
                    <Route index element={<Profile />} />
                    <Route path=":id" element={<EditProfile />} />
                    <Route path="auth" element={<AuthSettings />} />
                  </Route>
                {/*</Route>*/}

              </Route>{/* End Dash */}
            </Route>
          {/*</Route>*/}
        </Route>{/* End Protected Routes */}

      </Route>
    </Routes >
  );
}

export default App;

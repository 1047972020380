import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import ForgotPassword from './ForgotPassword'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Login = () => {
    useTitle('Employee Login')

    const userRef = useRef()
    //const errRef = useRef()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    //const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()
    const [showLoginForm, setShowLoginForm] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    //useEffect(() => {
    //    setErrMsg('');
    //}, [email, password])

    const handleSuccess = () =>
        toast.success("Welcome!", {
            position: "top-center",
            transition: Slide,
            autoClose: 1000,
            onClose: () => navigate('/dash')
        })

    const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 1500,
            onClose: () => navigate('/auth/login-step2')
        })

    const handleError = (err) =>
        toast.error(err, {
            position: "top-center",
            transition: Slide,
            autoClose: 5000
        })

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken, twofaEnabled, message } = await login({ email, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setEmail('')
            setPassword('')
            setShowLoginForm(false)
            if (twofaEnabled) {
                handleInfo(message)
                //setTimeout(() => {
                //    navigate('/auth/login-step2')
                //}, 2000)
                //alert(message)
            } else {
                handleSuccess()
                //setTimeout(() => {
                //    navigate('/dash')
                //}, 1500)
                //setTwofa(twofaEnabled)
                //navigate('/dash')
                //alert(message)
            }
        } catch (err) {
            if (!err.status) {
                handleError('No Server Response')
                //setErrMsg('No Server Response')
            } else if (err.status === 400) {
                handleError(err.data?.message)
                //setErrMsg('Missing Email or Password')
            } else if (err.status === 401) {
                handleError(err.data?.message)
                //setErrMsg('Unauthorized')
            } else {
                handleError(err.data?.message)
                //setErrMsg(err.data?.message);
            }
            //errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    //const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <section className="public">
            <header>
                <h1>Employee Login</h1>
            </header>
            {showLoginForm && (
                <main className="login">
                    {/*<p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>*/}

                    <form className="form" onSubmit={handleSubmit}>
                        <label htmlFor="email">Email:</label>
                        <input
                            className="form__input"
                            type="text"
                            id="email"
                            ref={userRef}
                            value={email}
                            onChange={handleUserInput}
                            autoComplete="off"
                            required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            className="form__input"
                            type="password"
                            id="password"
                            onChange={handlePwdInput}
                            value={password}
                            required
                        />
                        <button className="form__submit-button">Sign In</button>


                        <label htmlFor="persist" className="form__persist">
                            <input
                                type="checkbox"
                                className="form__checkbox"
                                id="persist"
                                onChange={handleToggle}
                                checked={persist}
                            />
                            Trust This Device
                        </label>
                    </form>
                </main>
            )}
            <ToastContainer />
            {showLoginForm && (
                <footer>
                    <Link to="/">Back to Home</Link>
                    <span className="float-end"><ForgotPassword /></span>
                </footer>
            )}

        </section>
    )

    return content
}
export default Login
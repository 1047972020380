import { useState, useEffect } from "react"
import { useAddNewUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"
import useTitle from "../../hooks/useTitle"
import useAuth from "../../hooks/useAuth"


const USER_REGEX = /^[А-Я][а-я]{1,20}$/ // /^[A-Z][a-z]{1,20}$/
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
//const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ // /^[A-z0-9!@#$%]{4,12}$/

const NewUserForm = () => {

    const { status } = useAuth()

    useTitle('Add New User')

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const navigate = useNavigate()

    const [firstname, setFirstname] = useState('')
    const [validFirstname, setValidFirstname] = useState(false)
    const [middlename, setMiddlename] = useState('')
    const [validMiddlename, setValidMiddlename] = useState(false)
    const [surname, setSurname] = useState('')
    const [validSurname, setValidSurname] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    //const [password, setPassword] = useState('')
    //const [validPassword, setValidPassword] = useState(false)
    //const [confirmPassword, setConfirmPassword] = useState('')
    //const [validConfPassword, setValidConfPassword] = useState(false)
    const [roles, setRoles] = useState(["Employee"])

    //const validatePassword = () => password === confirmPassword

    useEffect(() => {
        setValidFirstname(USER_REGEX.test(firstname))
    }, [firstname])

    useEffect(() => {
        setValidMiddlename(USER_REGEX.test(middlename))
    }, [middlename])

    useEffect(() => {
        setValidSurname(USER_REGEX.test(surname))
    }, [surname])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    //useEffect(() => {
    //    setValidPassword(PWD_REGEX.test(password))
    //}, [password])

    //useEffect(() => {
    //    setValidConfPassword(PWD_REGEX.test(confirmPassword))
    //}, [confirmPassword])

    useEffect(() => {
        if (isSuccess) {
            setFirstname('')
            setMiddlename('')
            setSurname('')
            setEmail('')
            //setPassword('')
            setRoles([])
            navigate('/dash/users')
        }
    }, [isSuccess, navigate])

    const onFirstnameChanged = e => setFirstname(e.target.value)
    const onMiddlenameChanged = e => setMiddlename(e.target.value)
    const onSurnameChanged = e => setSurname(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    //const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions, //HTMLCollection 
            (option) => option.value
        )
        setRoles(values)
    }

    const canSave = [roles.length, validFirstname, validMiddlename, validSurname, validEmail, /*validPassword, validatePassword()*/].every(Boolean) && !isLoading

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ firstname, middlename, surname, email, /*password,*/ roles })
        }
    }



    //const newArray = Object.values(ROLES).map((obj, index) => {
    //    Object.assign({}, obj, { id: index })
    //})

    //const newArray = Object.keys(ROLES).map((key) => ROLES[key])

    const newArray = Object.keys(ROLES).map((key) => ROLES[key])

    //console.log(newArray)
    //console.log("newArraynewArray")

    //const newArray2 = newArray.filter(x => x > newArray.indexOf(x===status))

    const allowedRoles = newArray.filter(x => newArray.indexOf(x) < newArray.indexOf(status))

    //console.log(allowedRoles)
    //console.log("statusRoles")

    const options = allowedRoles.map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validFirstnameClass = !validFirstname ? 'form__input--incomplete' : ''
    const validMiddlenameClass = !validMiddlename ? 'form__input--incomplete' : ''
    const validSurnameClass = !validSurname ? 'form__input--incomplete' : ''
    const validEmailClass = !validEmail ? 'form__input--incomplete' : ''
    //const validPwdClass = !validPassword ? 'form__input--incomplete' : ''
    //const validConfPwdClass = !validConfPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''


    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveUserClicked}>
                <div className="form__title-row">
                    <h2>Add New User Data</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="firstname">
                    Firstname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                <input
                    className={`form__input ${validFirstnameClass}`}
                    id="firstname"
                    name="firstname"
                    type="text"
                    autoComplete="off"
                    value={firstname}
                    onChange={onFirstnameChanged}
                />

                <label className="form__label" htmlFor="middlename">
                    Middlename: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                <input
                    className={`form__input ${validMiddlenameClass}`}
                    id="middlename"
                    name="middlename"
                    type="text"
                    autoComplete="off"
                    value={middlename}
                    onChange={onMiddlenameChanged}
                />

                <label className="form__label" htmlFor="surname">
                    Surname: <span className="nowrap">[2-20 letters, first letter - Uppercase]</span></label>
                <input
                    className={`form__input ${validSurnameClass}`}
                    id="surname"
                    name="surname"
                    type="text"
                    autoComplete="off"
                    value={surname}
                    onChange={onSurnameChanged}
                />

                <label className="form__label" htmlFor="email">
                    e-mail: <span className="nowrap">[e-mail format only]</span></label>
                <input
                    className={`form__input ${validEmailClass}`}
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="off"
                    value={email}
                    onChange={onEmailChanged}
                />

                {/*
                <label className="form__label" htmlFor="password">
                    Password: <span className="nowrap">[min 8 chars incl. uppercase, digit and #?!@$%^&*-]</span></label>
                <input
                    className={`form__input ${validPwdClass}`}
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onPasswordChanged}
                />

                <label className="form__label" htmlFor="confirmPassword">
                    Confirm Password:</label>
                <input
                    className={`form__input ${validConfPwdClass}`}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {confirmPassword && (
                    <p
                        className='validation-message'
                        style={{ color: validatePassword() ? "green" : "red" }}
                    >
                        {validatePassword() ? "Password Match" : "Password Does not Match"}
                    </p>
                )}
                */}

                <label className="form__label" htmlFor="roles">
                    ASSIGNED ROLES:</label>
                <select
                    id="roles"
                    name="roles"
                    className={`form__select ${validRolesClass}`}
                    multiple={true}
                    size="3"
                    value={roles}
                    onChange={onRolesChanged}
                >
                    {options}
                </select>

            </form>
        </>
    )

    return content
}
export default NewUserForm
import { useAddNewMaterialMutation } from "./materialsApiSlice"
import useAuth from "../../hooks/useAuth"

const NewMaterialBtn = () => {

    const { userId } = useAuth()

    const [addNewMaterial, {
        isError,
        error
    }] = useAddNewMaterialMutation()

    const onAddMaterialRow = async (e) => {
        e.preventDefault()
        await addNewMaterial({ user: userId })
    }

    //const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            {/*<p className={errClass}>{error?.data?.message}</p>*/}
            <table
                className="
            table table-striped table-sm table-bordered
            table-hover
            m-0
            collapse!!!!!!!!!!!!!!!!!!!!!
          "
                id="collapseExample"
            >
                <tbody>
                    <tr>
                        <td colSpan="6" className="align-middle text-center">
                            <button
                                className="addRow_btn font-weight-bold"
                                onClick={onAddMaterialRow}
                            >
                                +
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )

    return content
}

export default NewMaterialBtn

import React, { useState, useEffect } from "react"
//import NewMaterialBtn from "../../invoice/NewMaterialBtn.js"
//import useAuth from "../../../hooks/useAuth"
import { useUpdateMaterialMutation } from "../materialsApiSlice"
import {
  //closestCenter,
  DndContext,
  TouchSensor,
  MouseSensor,
  //DragOverlay,
  //DragStartEvent,
  useSensor,
  useSensors,
  //PointerSensor,
  KeyboardSensor,
  //useDndContext,
  //LayoutMeasuringStrategy,
  //DragEndEvent
} from "@dnd-kit/core"

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from "@dnd-kit/sortable";

//import { Droppable } from "./Droppable";
//import { Draggable } from "./Draggable";
import SortableItem from "./SortableItem";

const Dnd = ({ materials }) => {

  //const { isManager, isAdmin } = useAuth()
  //const disabled = !(isManager || isAdmin) ? true : false

  const [updateMaterial] = useUpdateMaterialMutation()

  const updOrder = async (items) => {
    //console.log("items")
    //console.log(items)

    await Promise.all(items.slice(0).map((item, index) =>
      updateMaterial({ id: item._id, index: index })
    ))
  }

  const [items, setItems] = useState([])
  useEffect(() => {
    setItems(materials)
  }, [materials?.length])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  //const [isDropped, setIsDropped] = useState(false);
  //const [parent, setParent] = useState(null);

  //const containers = ["A", "B", "C"];

  //const draggableMarkup = <Draggable id="draggable">Drag me</Draggable>;
  const handleDragStart = (props) => {
    console.log(props);
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id)
        const newIndex = items.findIndex(({ id }) => id === over.id)
        //console.log("before")
        //console.dir(items)
        //console.log(oldIndex)
        //console.log(newIndex)
        updOrder(arrayMove(items, oldIndex, newIndex))
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleDragCancel = () => { }

  //console.log("after")
  //console.dir(items)

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <table
        className="
            table table-striped table-sm table-bordered
            table-hover
            m-0
            collapse!!!!!!!!!!!!!!!!!!!!!
          "
        id="collapseExample"
      >
        <tbody>
          <tr>
            <th scope="col" colSpan="3" className="align-middle text-center">
              Наименование
            </th>
            <th scope="col" className="align-middle text-center">
              Ед. изм.
            </th>
            <th scope="col" className="align-middle text-center">
              Кол-во
            </th>
            <th scope="col" className="align-middle text-center">
              Стоимость
            </th>
          </tr>
          <SortableContext items={items}>
            {items.map((x) => (

              <SortableItem key={x._id} material={x} />
            )
            )}
          </SortableContext>
          {/*!disabled && <NewMaterialBtn />*/}
        </tbody>
      </table>
    </DndContext>
  )
}

export default Dnd

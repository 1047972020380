import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useResetpwdotpMutation } from './authApiSlice'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import OtpInput from "./OtpInput"
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ResetPwdOtp = () => {
    useTitle('Password Restoration')

    const { resetToken } = useParams()
    const navigate = useNavigate()
    const userRef = useRef()
    //const errRef = useRef()

    const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

    const styles = {
        btn: {
            color: "gray",
        }
    }

    const [resetpwdotp, {
        //data: resetpswdata,
        isLoading//: isResetLoading,
        //isSuccess: isResetSuccess,
        //isError: isResetError,
        //error: reseterror
    }] = useResetpwdotpMutation()

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [validConfPassword, setValidConfPassword] = useState(false)

    //const [otp, setOtp] = useState('')

    const [showPwdChangeDialog, setShowPwdChangeDialog] = useState(true)
    const [showModalOtp, setShowModalOtp] = useState(false)
    //const [errMsg, setErrMsg] = useState('')

    const onPasswordChanged = e => setPassword(e.target.value)
    const onConfirmPasswordChanged = e => setConfirmPassword(e.target.value)

    const comparePassword = () => password === confirmPassword

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        if (showModalOtp) {
            userRef.current.focus()
        }
    }, [showModalOtp])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        setValidConfPassword(PWD_REGEX.test(confirmPassword))
    }, [confirmPassword])

    //useEffect(() => {
    //    setErrMsg('')
    //}, [password])

    const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 4500,
            onClose: () => navigate('/login')
        })

    const handleError = (err) =>
        toast.error(err, {
            position: "top-center",
            transition: Slide,
            autoClose: 4000,
            onClose: () => navigate('/')
        })

    /*useEffect(() => {
        if (isResetSuccess) {
            alert(resetpswdata?.message)
            setPassword('')
            setConfirmPassword('')
            //setOtp('')
            setShowModalOtp(false)
            navigate('/login')
        }
        if (isResetError) {
            alert(reseterror?.data?.message)
            //setPassword('')
            //setConfirmPassword('')
            //setResetSmsCode('')
            setShowModalOtp(true)
            //navigate('/')
        }
    }, [isResetSuccess || isResetError])*/

    let canSave
    if (password) {
        canSave = [validPassword, comparePassword()].every(Boolean)
    }

    const onSavePasswordClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            setShowModalOtp(true)
        }
    }

    const onOtpSubmit = async (otp) => {
        if (canSave) {
            try {
                const { message } = await resetpwdotp({ resetToken, password, otp }).unwrap()
                setPassword('')
                setConfirmPassword('')
                setShowModalOtp(false)
                setShowPwdChangeDialog(false)
                handleInfo(message)
                //setTimeout(() => {
                //    navigate('/login')
                //}, 5000)
            } catch (err) {
                if (!err.status) {
                    handleError('No Server Response')
                } else if (err.status === 400) {
                    handleError(err.data?.message)
                } else if (err.status === 401) {
                    handleError(err.data?.message)
                } else {
                    handleError(err.data?.message)
                }
                setPassword('')
                setConfirmPassword('')
                setShowModalOtp(false)
                setShowPwdChangeDialog(false)
                //setTimeout(() => {
                //    navigate('/')
                //}, 4500)
            }
        }
    }

    const validNewPwdClass = !validPassword ? 'form__input--incomplete' : ''
    const validConfPwdClass = !validConfPassword ? 'form__input--incomplete' : ''

    //const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <section className="public">
            <header>
                <h1>Password Restoration</h1>
            </header>
            {showPwdChangeDialog && (
                <main className="login">
                    {/*<p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>*/}

                    <div className="col">
                        <label className="form__label" htmlFor="password">New Password:</label><br />
                        <input
                            className={`form__input ${validNewPwdClass}`}
                            type="password"
                            id="password"
                            ref={userRef}
                            value={password}
                            onChange={onPasswordChanged}
                            placeholder="password"
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="col">
                        <label className="form__label" htmlFor="confirmPassword">
                            Confirm New Password:</label><br />
                        <input
                            className={`form__input ${validConfPwdClass}`}
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={onConfirmPasswordChanged}
                            placeholder="password"
                            autoComplete="off"
                            required
                        />
                    </div>

                    {confirmPassword && (
                        <p
                            className='validation-message'
                            style={{ color: comparePassword() ? "green" : "red" }}
                        >
                            {comparePassword() ? "Password Match" : "Password Does not Match"}
                        </p>
                    )}

                    <pre />

                    <div className="row text-center">
                        <button
                            className="icon-button"
                            style={!canSave ? (styles.btn) : {}}
                            title="Save"
                            onClick={onSavePasswordClicked}
                            disabled={!canSave}
                        >
                            Save&nbsp;
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>



                    <Modal
                        show={showModalOtp}
                        onHide={() => setShowModalOtp(false)}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <p>Enter OTP:&nbsp;<b>given You by Authenticator</b></p>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span className="text-center">
                                <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col text-start">
                                <Button variant="secondary" onClick={() => setShowModalOtp(false)}>
                                    Close
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </main>
            )}
            <footer>
                {showPwdChangeDialog && (<Link to="/">Back to Home</Link>)}
            </footer>
            <ToastContainer />
        </section>
    )

    return content
}
export default ResetPwdOtp
import { createSlice } from '@reduxjs/toolkit'

/*const initialState = {
    otpSmsState: "",
};*/

const authSettsSlice = createSlice({
    name: 'authsettings',
    initialState: {otpSmsState: ""},
    reducers: {
        setOtpSms: (state, action) => {
            state.otpSmsState = action.payload;
            //const { otpSmsEnabled } = action.payload
            //state.otpSmsEnabled = otpSmsEnabled
        }/*,
        logOut: (state, action) => {
            state.token = null
        },*/
    }
})

export const { setOtpSms } = authSettsSlice.actions

export default authSettsSlice.reducer

//export const selectOtpSmsEnabled = (state) => state.authsettings.otpSmsEnabled
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileCirclePlus,
    faFilePen,
    faUserGear,
    faUserPlus,
    faRightFromBracket,
    faFileInvoiceDollar,
    faUser,
    faFingerprint,
    faLock,
    faGear
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import useAuth from '../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'

//const DASH_REGEX = /^\/dash(\/)?$/
const NOTES_REGEX = /^\/dash\/notes(\/)?$/
const USERS_REGEX = /^\/dash\/users(\/)?$/
//const INVOICE_REGEX = /^\/dash\/invoice(\/)?$/

const DashHeader = () => {
    const { userId, isManager, isAdmin, isOwner } = useAuth()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    const onHomeClicked = () => navigate('/dash')
    const onNewNoteClicked = () => navigate('/dash/notes/new')
    const onNewUserClicked = () => navigate('/dash/users/new')
    const onNotesClicked = () => navigate('/dash/notes')
    const onUsersClicked = () => navigate('/dash/users')
    const onInvoiceClicked = () => navigate('/dash/invoices')
    const onProfileClicked = () => navigate('/dash/profile')
    const onAuthSettingsClicked = () => navigate('/dash/profile/auth')
    const onCredentialsButton = () => navigate(`/dash/profile/credentials/${userId}`)

    //let dashClass = null
    //if (!DASH_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !USERS_REGEX.test(pathname)) {
    //    dashClass = "dash-header__container--small"
    //}

    let newNoteButton = null
    if (NOTES_REGEX.test(pathname)) {
        newNoteButton = (
            <button
                className="icon-button"
                title="New Note"
                onClick={onNewNoteClicked}
            >
                &nbsp;
                <FontAwesomeIcon icon={faFileCirclePlus} />
                Add note
            </button>
        )
    }

    let newUserButton = null
    if (USERS_REGEX.test(pathname)) {
        newUserButton = (
            <button
                className="icon-button"
                title="New User"
                onClick={onNewUserClicked}
            >
                &nbsp;
                <FontAwesomeIcon icon={faUserPlus} />
                Add user
            </button>
        )
    }

    let userButton = null
    if (isManager || isAdmin || isOwner) {
        if (!USERS_REGEX.test(pathname) && pathname.includes('/dash')) {
            userButton = (
                <button
                    className="icon-button"
                    title="Users"
                    onClick={onUsersClicked}
                >
                    &nbsp;
                    <FontAwesomeIcon icon={faUserGear} />
                    Users
                </button>
            )
        }
    }

    let notesButton = null
    if (!NOTES_REGEX.test(pathname) && pathname.includes('/dash')) {
        notesButton = (
            <button
                className="icon-button"
                title="Notes"
                onClick={onNotesClicked}
            >
                &nbsp;
                <FontAwesomeIcon icon={faFilePen} />
                Notes
            </button>
        )
    }

    //let invoiceButton = null
    //if (!INVOICE_REGEX.test(pathname) && pathname.includes('/dash')) {
    const invoiceButton = (
        <button
            className="icon-button"
            title="Invoice"
            onClick={onInvoiceClicked}
        >
            &nbsp;
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
            Квитанция
        </button>
    )
    //}

    const profileButton = (
        <button
            className="icon-button"
            title="Profile"
            onClick={onProfileClicked}
        >
            <FontAwesomeIcon icon={faUser} />
            &nbsp;Profile
        </button>
    )

    const authSettingsButton = (
        <button
            className="icon-button"
            title="Authentication"
            onClick={onAuthSettingsClicked}
        >
            <FontAwesomeIcon icon={faFingerprint} />
            &nbsp;Authentication
        </button>
    )

    const credentialsButton = (
        <button
            className="icon-button"
            title="Credentials"
            onClick={onCredentialsButton}
        >
            <FontAwesomeIcon icon={faLock} />
            &nbsp;Credentials
        </button>
    )

    const logoutButton = (
        <button
            className="icon-button"
            title="Logout"
            onClick={sendLogout}
        >
            Log out:&nbsp;
            <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
    )

    const homeButton = (
        <button
            className="border-0 bg-transparent"
            title="Logout"
            onClick={onHomeClicked}
        >
            <img src="/img/clover.png" className="clever_logo" />
        </button>
    )

    const errClass = isError ? "errmsg" : "offscreen"

    let buttonContent
    if (isLoading) {
        buttonContent = <PulseLoader color={"#FFF"} />
    } else {
        buttonContent = (
            <>
                {homeButton}
                {invoiceButton}
                {newNoteButton}
                {newUserButton}
                {notesButton}
                {userButton}
                {profileButton}
                {authSettingsButton}
                {credentialsButton}
                {logoutButton}
            </>
        )
    }

    const content = (
        <>
            {/*(<header className="dash-header">
                <div className={"dash-header__container}"}>
                    <Link to="/dash">
                        <h1 className="dash-header__title">techNotes</h1>
                    </Link>
                    <nav className="dash-header__nav">
                        {buttonContent}
                    </nav>
                </div>
            </header>)*/}
            <Navbar collapseOnSelect expand="sm" /*className="bg-body-tertiary"*/ className="dash-header" sticky="top">
                <Container fluid>
                    <Navbar.Brand>
                        <Nav.Link eventKey="0">{homeButton}</Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link eventKey="1">{invoiceButton}</Nav.Link>
                            <Nav.Link eventKey="2">{notesButton}</Nav.Link>
                            <Nav.Link eventKey="3">{newNoteButton}</Nav.Link>
                            <Nav.Link eventKey="4">{userButton}</Nav.Link>
                            <Nav.Link eventKey="5">{newUserButton}</Nav.Link>
                        </Nav>
                        <Nav>
                            <NavDropdown title={"Settings"} id="collapsible-nav-dropdown" className="icon-gear">
                                <NavDropdown.Item eventKey="6">{profileButton}</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey="7">{credentialsButton}</NavDropdown.Item>
                                <NavDropdown.Item eventKey="8">{authSettingsButton}</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey="9">{logoutButton}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <p className={errClass}>{error?.data?.message}</p>
        </>
    )

    return content
}
export default DashHeader

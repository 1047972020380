import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useResetpasswordMutation } from './authApiSlice'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ResetPassword = () => {
    useTitle('Password Restoration')

    const { resetToken } = useParams()
    const navigate = useNavigate()
    const userRef = useRef()
    //const errRef = useRef()

    const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

    const styles = {
        btn: {
            color: "gray",
        }
    }

    const [resetpassword, {
        //data,
        isLoading,
        //isSuccess,
        //isError,
        //error
    }] = useResetpasswordMutation()

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [validConfPassword, setValidConfPassword] = useState(false)

    const [showPwdChangeDialog, setShowPwdChangeDialog] = useState(true)

    //const [errMsg, setErrMsg] = useState('')

    const onPasswordChanged = e => setPassword(e.target.value)
    const onConfirmPasswordChanged = e => setConfirmPassword(e.target.value)

    const comparePassword = () => password === confirmPassword

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        setValidConfPassword(PWD_REGEX.test(confirmPassword))
    }, [confirmPassword])

    //useEffect(() => {
    //    setErrMsg('')
    //}, [password])

    const handleInfo = (message) =>
        toast.info(message, {
            position: "top-center",
            transition: Slide,
            autoClose: 4500,
            onClose: () => navigate('/login')
        })

    const handleError = (err) =>
        toast.error(err, {
            position: "top-center",
            transition: Slide,
            autoClose: 4000,
            onClose: () => navigate('/')
        })

    /*useEffect(() => {
        if (isSuccess) {
            setPassword('')
            setConfirmPassword('')
            handleInfo(data?.message)
            setTimeout(() => {
                navigate('/login')
            }, 4000)
            //navigate('/login')
            //alert(data?.message)
        }
        if (isError) {
            handleError(error?.data?.message)
            alert(error?.message)
        }
    }, [isError || isSuccess])*/

    let canSave
    if (password) {
        canSave = [validPassword, comparePassword()].every(Boolean) && !isLoading
    }

    const onSavePasswordClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            try {
                const { message } = await resetpassword({ resetToken, password }).unwrap()
                setPassword('')
                setConfirmPassword('')
                setShowPwdChangeDialog(false)
                handleInfo(message)
                //setTimeout(() => {
                //    navigate('/login')
                //}, 5000)
            } catch (err) {
                if (!err.status) {
                    handleError('No Server Response')
                } else if (err.status === 400) {
                    handleError(err.data?.message)
                } else if (err.status === 401) {
                    handleError(err.data?.message)
                } else {
                    handleError(err.data?.message)
                }
                setPassword('')
                setConfirmPassword('')
                setShowPwdChangeDialog(false)
                //setTimeout(() => {
                //    navigate('/')
                //}, 4500)
            }
        }
    }

    const validNewPwdClass = !validPassword ? 'form__input--incomplete' : ''
    const validConfPwdClass = !validConfPassword ? 'form__input--incomplete' : ''

    //const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <section className="public">
            <header>
                <h1>Password Restoration</h1>
            </header>
            {showPwdChangeDialog && (
                <main className="login">
                    {/*<p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>*/}

                    <div className="col">
                        <label className="form__label" htmlFor="password">New Password:</label><br />
                        <input
                            className={`form__input ${validNewPwdClass}`}
                            type="password"
                            id="password"
                            ref={userRef}
                            value={password}
                            onChange={onPasswordChanged}
                            placeholder="password"
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="col">
                        <label className="form__label" htmlFor="confirmPassword">
                            Confirm New Password:</label><br />
                        <input
                            className={`form__input ${validConfPwdClass}`}
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={onConfirmPasswordChanged}
                            placeholder="password"
                            autoComplete="off"
                            required
                        />
                    </div>

                    {confirmPassword && (
                        <p
                            className='validation-message'
                            style={{ color: comparePassword() ? "green" : "red" }}
                        >
                            {comparePassword() ? "Password Match" : "Password Does not Match"}
                        </p>
                    )}

                    <pre />

                    <div className="row text-center">
                        <button
                            className="icon-button"
                            style={!canSave ? (styles.btn) : {}}
                            title="Save"
                            onClick={onSavePasswordClicked}
                            disabled={!canSave}
                        >
                            Save&nbsp;
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>

                </main>
            )}
            <footer>
                {showPwdChangeDialog && (<Link to="/">Back to Home</Link>)}
            </footer>
            <ToastContainer />
        </section>
    )

    return content
}
export default ResetPassword
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './weather.css'
import Ctime from '../addons/Ctime'
import FWeather from '../addons/FWeather'
import Modal from 'react-bootstrap/Modal'

// current weather
const CWeather = () => {
    const iconStyle = {
        width: "30px",
        height: "30px"
    }

    const pointerStyle = {
        cursor: "pointer"
    }

    const url = `https://api.openweathermap.org/data/2.5/weather?lat=52.2138&lon=24.3564&appid=7bceb8626cd8e2e8d4ab9505b44c82bd&units=metric&lang=ru`
    //const url = `https://api.openweathermap.org/data/3.0/onecall?lat={lat}&lon={lon}&exclude={part}&appid=7bceb8626cd8e2e8d4ab9505b44c82bd`

    const [data, setData] = useState({})
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const icon = data.weather ? data.weather[0].icon : null

    const iconUrl = `https://openweathermap.org/img/wn/${icon}@2x.png`
    //console.log(icon)

    const description = data.weather ? data.weather[0].description.charAt(0).toUpperCase() + data.weather[0].description.slice(1) : "" //makes description with Uppercase first letter

    useEffect(() => {
        axios.get(url).then((response) => {
            setData(response.data)
            //console.log(response.data)
        })
    }, [])

    return (
        <div className="p-3 col">
            <div className="top rcorners2">
                <div className="location">
                    <Ctime />
                    <div className="d-flex">
                        <h2 className="me-auto">{data.name}</h2>
                        <span onClick={handleShow}>
                            <img src="weather_forecast.png" style={pointerStyle} />
                        </span>
                    </div>
                </div>
                <div className="temp d-flex justify-content-between align-items-center">
                    {data.main ? <h1>{data.main.temp.toFixed()}°C</h1> : null}
                    <img src={iconUrl} />
                </div>
                {data.name !== undefined &&
                    <div className="bottom">
                        {/*<div className="description">
                        {data.weather ? <p>{description}</p> : null}
                    </div>
                    <div className="feels">
                        <p>По ощущениям как: {data.main ? <span className='bold'>{data.main.feels_like.toFixed()}°C</span> : null}</p>
                    </div>*/}
                        <div className="humidity">
                            <span className="justify-content-between">
                                <img src="humidity.png" style={iconStyle} />
                                {data.main ? <strong className='bold'>{data.main.humidity}%</strong> : null}
                            </span>
                            <span className="ps-2">
                                <img src="windsock.png" style={iconStyle} />
                                {data.wind ? <strong className='bold'>{data.wind.speed.toFixed()} м/с</strong> : null}
                            </span>
                        </div>
                        <div className="wind">

                        </div>
                    </div>
                }
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>5 Days Weather Forecast</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FWeather />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CWeather

import useAuth from "../../hooks/useAuth"
import { useGetUsersQuery } from '../users/usersApiSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    //faUserEdit,
    //faSave,
    //faTrashCan,
    faPenToSquare
} from "@fortawesome/free-solid-svg-icons"
import useTitle from '../../hooks/useTitle'
import { useNavigate } from 'react-router-dom'
import { memo } from 'react'
//import AuthSettings from './AuthSettings'

const Profile = () => {
    useTitle('Profile')

    const { userId } = useAuth()
    const navigate = useNavigate()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })
    //console.dir("userfsfwef")
    //console.dir(user)
    /*const getUppercases = () => {
        //const nameArray = (user.firstname+" "+user.middlename).split(" ")
        const firstname = user.firstname ? user.firstname?.charAt(0).toUpperCase() + "." : ""
        const middlename = user.middlename ? user.middlename.charAt(0).toUpperCase() + "." : ""
        return firstname + middlename
    }

    let initials = getUppercases()*/

    if (user) {
        const handleEdit = () => navigate(`/dash/profile/${userId}`)

        const options = Object.values(user.roles).map(role => {
            return (
                <option
                    key={role}
                    value={role}

                > {role}</option >
            )
        })

        const content = (
            <>
                {/*<AuthSettings user={user} />*/}
                <form className="form">
                    <div className="form__title-row">
                        <h2>User Profile:</h2>
                    </div>
                    <label className="form__label" htmlFor="firstname">
                        Firstname:</label>
                    <input
                        className="form__input"
                        id="firstname"
                        name="firstname"
                        type="text"
                        value={user.firstname}
                        readOnly
                    />

                    <label className="form__label" htmlFor="middlename">
                        Middlename:</label>
                    <input
                        className="form__input"
                        id="middlename"
                        name="middlename"
                        type="text"
                        value={user.middlename}
                        readOnly
                    />

                    <label className="form__label" htmlFor="surname">
                        Surname:</label>
                    <input
                        className="form__input"
                        id="surname"
                        name="surname"
                        type="text"
                        value={user.surname}
                        readOnly
                    />

                    <label className="form__label" htmlFor="email">
                        Your e-mail:</label>
                    <input
                        className="form__input"
                        id="email"
                        name="email"
                        type="text"
                        value={user.email}
                        readOnly
                    />

                    <label className="form__label" htmlFor="roles">
                        ASSIGNED ROLES:</label>
                    <select
                        id="roles"
                        name="roles"
                        className="form__input"
                        multiple={true}
                        size="3"
                        value={user.roles}
                        disabled
                    >
                        {options}
                    </select>
                    <button
                        className="icon-button"
                        title="Edit"
                        onClick={handleEdit}
                    >
                        Edit&nbsp;
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </form>
            </>
        )

        return content

    } else return null
}

const memoizedProfile = memo(Profile)

export default memoizedProfile















//const handleEdit = () => navigate(`/dash/users/${userId}`)

//const userRolesString = user.roles.toString().replaceAll(',', ', ')

/*const cellStatus = user.active ? '' : 'table__cell--inactive'*/

//return (
//    <tr className="table__row user">
//        <td className={`table__cell ${cellStatus}`}>{user.surname + " " + initials}</td>
//        <td className={`table__cell ${cellStatus}`}>{user.email}</td>
//        <td className={`table__cell ${cellStatus}`}>{userRolesString}</td>
//        <td className={`table__cell ${cellStatus}`}>
//            <button
//                className="icon-button table__button"
//                onClick={handleEdit}
//            >
//                <FontAwesomeIcon icon={faPenToSquare} />
//            </button>
//        </td>
//    </tr>
//)
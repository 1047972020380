import { useGetUsersQuery } from '../users/usersApiSlice'
import { useState } from "react"
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import Ctime from '../addons/Ctime'
import CWeather from '../addons/CWeather'
import FWeather from '../addons/FWeather'

const Welcome = () => {

    const { userId, isManager, isAdmin, isOwner } = useAuth()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    useTitle(`Welcome to Kvitok App`)

    /*let time = new Date().toLocaleTimeString('ru-RU')

    const [ctime, setTime] = useState(time)
    const UpdateTime = () => {
        time = new Date().toLocaleTimeString('ru-RU')
        setTime(time)
    }
    setInterval(UpdateTime)*/

    //const date = new Date()
    //const today = new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(date)

    const content = (
        <section className="welcome">

            {/*<h1>Welcome {user?.firstname}!</h1>}

            {/*<Ctime />
            <div>
                <div className="row">*/}
                    <CWeather />
                    {/*<p>{ctime}</p>
                </div>
            </div>*/}
            {/*
            <p><Link to="/dash/notes">View techNotes</Link></p>

            <p><Link to="/dash/notes/new">Add New techNote</Link></p>

            {(isManager || isAdmin || isOwner) && <p><Link to="/dash/users">View User Settings</Link></p>}

            {(isManager || isAdmin || isOwner) && <p><Link to="/dash/users/new">Add New User</Link></p>}
            */}

        </section>
    )

    return content
}
export default Welcome

import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isManager = false
    let isAdmin = false
    let isOwner = false
    let status = "Employee"

    if (token) {
        const decoded = jwtDecode(token)
        const { userId, email, roles, twofa } = decoded.UserInfo

        if (roles) {
            isManager = roles.includes('Manager')
            isAdmin = roles.includes('Admin')
            isOwner = roles.includes('Owner')
        }

        if (isManager) status = "Manager"
        if (isAdmin) status = "Admin"
        if (isOwner) status = "Owner"

        return { userId, email, roles, twofa, status, isManager, isAdmin, isOwner }
    }

    return { userId: '', email: '', roles: [], twofa: '', isManager, isAdmin, isOwner, status }
}
export default useAuth
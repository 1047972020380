import React, { useState, useEffect } from "react"
import "./style.css"
import helpers from "./helpers.js"
import InputSpinner from "./InputSpinner.js"
import AddSorMRowButton from "./AddSorMRowButton.js"
import ServicesData from "./services.json"
import { useSelector, shallowEqual } from 'react-redux'
import InvoicesList from "./InvoicesList.js"
import MsList from "./MsList.js"
import { useGetMaterialsQuery } from "./materialsApiSlice"
import { useDispatch } from 'react-redux'
import { setPrice } from './PriceSlice'
import { useUpdateMaterialMutation } from "./materialsApiSlice"
import { useAddNewInvoiceMutation } from "./invoicesApiSlice"
import useAuth from "../../hooks/useAuth"
import { useGetUsersQuery } from '../users/usersApiSlice'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'
import NewMaterialBtn from "./NewMaterialBtn.js"

const Invoice = () => {

  useTitle('Invoice')

  const s_VATpercent = 20
  const m_VATpercent = 20

  const [serv_rows, setServices] = useState([])

  const handleAddService = () => {
    const empty_s_row = helpers.newServRow()
    setServices([...serv_rows, empty_s_row])
    //console.log(empty_s_row)
  }

  const [material_rows, setMaterials] = useState([])

  const handleAddMaterial = () => {
    const empty_m_row = helpers.newMaterialRow()
    setMaterials([...material_rows, empty_m_row])
    //console.log(empty_m_row)
  }

  const editServiceRow = attrs => {
    setServices(prevState => {
      const newState = prevState.map(obj => {
        if (obj.id === attrs.id)
          return Object.assign({}, obj, attrs)
        return obj
      })
      //setInvoice({...invoice, materialRows: newState});
      return newState
    })
  }

  const editMaterialRow = attrs => {
    setMaterials(prevState => {
      const newState = prevState.map(obj => {
        if (obj.id === attrs.id)
          return Object.assign({}, obj, attrs)
        return obj
      })
      //setInvoice({...invoice, materialRows: newState});
      return newState
    })
  }

  const deleteServiceRow = serv_rowId => {
    setServices(current =>
      current.filter(sr => {
        return sr.id !== serv_rowId
      })
    )
  }

  const deleteMaterialRow = material_rowId => {
    setMaterials(current =>
      current.filter(mr => {
        return mr.id !== material_rowId
      })
    )
  }

  return (
    <div className="container">
      <InvoiceList
        serv_rows={serv_rows}
        services={ServicesData}
        onTrashClickService={deleteServiceRow}
        editServiceRow={editServiceRow}
        onAddService={handleAddService}
        s_VATpercent={s_VATpercent}

        material_rows={material_rows}
        onTrashClickMaterial={deleteMaterialRow}
        editMaterialRow={editMaterialRow}
        onAddMaterial={handleAddMaterial}
        m_VATpercent={m_VATpercent}
      />
      <MaterialsList />
      <InvoicesList />
    </div>
  )
}
/*
  axios
    //.post('http://localhost:3500/invoices', invoice)
    .then((res) => {
      console.log(res.data);
      props.onAddInvoice(res.data);
      //Object.assign(empty_m, {_id: res.data});
      //setMaterial([...materials, empty_m]);
    })
    .catch((err) => {
      console.log('Error in CreateInvoice!');
    })
*/
const InvoiceList = (props) => {

  const { userId } = useAuth()

  const { user } = useGetUsersQuery("usersList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userId]
    }),
  })

  let username

  if (user) {
    const getUppercases = () => {
      const firstname = user.firstname ? user.firstname?.charAt(0).toUpperCase() + "." : ""
      const middlename = user.middlename ? user.middlename.charAt(0).toUpperCase() + "." : ""
      return firstname + middlename
    }
    const initials = getUppercases()
    username = user.surname + " " + initials
  }

  const [addNewInvoice] = useAddNewInvoiceMutation()

  const [totallS, setTotallService] = useState()
  const [totallM, setTotallMaterial] = useState()
  const [invoice, setInvoice] = useState({}) //WHY ARE THE BRACKETS{} HERE?

  const vatS = 1 + props.s_VATpercent / 100
  const vatM = 1 + props.m_VATpercent / 100
  const srows_sum = (totallS * vatS).toFixed(2)
  const mrows_sum = (totallM * vatM).toFixed(2)
  const total_sum_no_vat = (totallS + totallM).toFixed(2)
  const total_sum = Number(srows_sum) + Number(mrows_sum)

  const updateTotallService = e => {
    setTotallService(e)
  }

  const updateTotallMaterial = e => {
    setTotallMaterial(e)
  }

  useEffect(() => {
    setInvoice({
      userId: userId,
      username: username,
      serviceRows: props.serv_rows.filter(s_row => s_row.title != ""),
      srows_sum_no_vat: totallS,
      srows_VAT: vatS,
      materialRows: props.material_rows.filter(m_row => m_row.item_id != ""),
      mrows_sum_no_vat: totallM,
      mrows_VAT: vatM,
      total_sum_no_vat: total_sum_no_vat,
      total_sum: total_sum
    })
    /*console.dir(props.material_rows.filter(m_row => m_row.item_id != ""))
    console.log("props.material_rows.filter")*/
  }, [totallS, totallM, props.serv_rows, props.material_rows])

  const handleAddInvoice = async (e) => {
    e.preventDefault()
    //if (canSave) {
    /*console.log("Hi_InvoiceList")
    console.dir(invoice)*/
    await addNewInvoice(invoice)
    //}
  }

  const serv_rows = props.serv_rows.map(serv_row => (
    <ServiceRow
      key={serv_row.id}
      serv_row={serv_row}
      onTrashClickService={props.onTrashClickService}
      editServiceRow={props.editServiceRow}
      services={props.services}
      s_VATpercent={props.s_VATpercent}
    />
  ))
  const material_rows = props.material_rows.map(material_row => (
    <MaterialRow
      key={material_row.id}
      material_row={material_row}
      onTrashClickMaterial={props.onTrashClickMaterial}
      editMaterialRow={props.editMaterialRow}
      m_VATpercent={props.m_VATpercent}
      userId={userId}
    />
  ))

  return (
    <div className="row">
      <div className="col">
        <h2 className="d-flex justify-content-center">
          <b>АКТ-КВИТАНЦИЯ</b>
        </h2>
        <p className="d-flex justify-content-center">
          на выполнение дополнительных видов работ (услуг)
        </p>
        <p className="d-flex justify-content-center">
          <b>
            Тарифы на дополнительные виды работ по абонентским пунктам
            Приложение 46. Вводятся с 01 мая 2022 года
          </b>
        </p>
      </div>
      <div>
        <table className="table table-sm table-bordered table-striped table-hover m-0">
          <tbody>
            <tr>
              <th scope="col" className="align-middle text-center">
                Наименование работ (услуг)
              </th>
              <th sscope="col" className="align-middle text-center">
                Тариф без НДС
              </th>
              <th scope="col" className="align-middle text-center">
                НДС {props.s_VATpercent}%
              </th>
              <th scope="col" className="align-middle text-center">
                Тариф с НДС
              </th>
              <th scope="col" className="align-middle text-center">
                кол-во
              </th>
              <th scope="col" className="align-middle text-center">
                сумма,р
              </th>
            </tr>
            {serv_rows}
            <SumRows
              rows={props.serv_rows}
              VATpercent={props.s_VATpercent}
              setTotall={updateTotallService}
              text={"работы"}
            />
            <AddSorMRowButton onAddRow={props.onAddService} />
            <tr>
              <th scope="col" className="align-middle text-center">
                Наименование запчастей
              </th>
              <th sscope="col" className="align-middle text-center">
                Стоим. без НДС
              </th>
              <th scope="col" className="align-middle text-center">
                НДС {props.m_VATpercent}%
              </th>
              <th scope="col" className="align-middle text-center">
                Стоим. с НДС
              </th>
              <th scope="col" className="align-middle text-center">
                кол-во
              </th>
              <th scope="col" className="align-middle text-center">
                сумма,р
              </th>
            </tr>
            {material_rows}
            <SumRows
              rows={props.material_rows}
              VATpercent={props.m_VATpercent}
              setTotall={updateTotallMaterial}
              text={"материалы"}
            />
            <AddSorMRowButton onAddRow={props.onAddMaterial} />
            <TotalSum
              totallS={totallS}
              totallM={totallM}
              sVATpercent={props.s_VATpercent}
              mVATpercent={props.m_VATpercent}
            />
          </tbody>
        </table>
      </div>
      &nbsp;
      <div>
        <button
          disabled={(totallS === 0 && totallM === 0) ? true : false}
          className="add_invoice font-weight-bold"
          onClick={handleAddInvoice}
        >Выписать Квитанцию
        </button>
        &nbsp;
      </div>
    </div>
  )
}

const MaterialsList = () => {
  const { isManager, isAdmin } = useAuth()
  const disabled = !(isManager || isAdmin) ? true : false
  let content

  content = (
    <>
      <button
        type="button"
        className="materials_btn" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
      >
        Материалы
      </button>
      <MsList />
      {!disabled && <NewMaterialBtn />}
    </>
  )
  return content
}

const ServiceRow = (props) => {
  const s_VATpercent = props.s_VATpercent
  const vat = 1 + s_VATpercent / 100

  const [price, setPriceState] = useState(0)
  const [s_row, s_rowSetChange] = useState(props.serv_row) //PROBABLY NOT CORRECT!
  const [n, setN] = useState(0)

  const services = props.services

  const updateCount = e => {
    setN(e)
    const newState = Object.assign(s_row, {
      count: e
    })
    s_rowSetChange(newState)
    props.editServiceRow(newState)
  }

  const updatePrice = e => {
    const selected_price = services
      .filter(service => service.id === Number(e.target.value))
      .map(filteredService => filteredService.price)[0]

    const selected_title = services
      .filter(service => service.id === Number(e.target.value))
      .map(filteredService => filteredService.title)[0]

    setPriceState(selected_price)

    const newState = Object.assign(s_row, {
      title: selected_title,
      price: selected_price,
    })
    s_rowSetChange(newState)
    props.editServiceRow(newState)
  }

  const handleTrashClick = () => {
    props.onTrashClickService(s_row.id)
  }

  return (
    <tr>
      <td>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <select onChange={updatePrice} defaultValue="">
                <option></option>
                {services.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </td>
      <td>{price}</td>
      <td>{(price * (vat - 1)).toFixed(2)}</td>
      <td>{(price * vat).toFixed(2)}</td>
      <td>
        <InputSpinner step={1.0} onChange={count => updateCount(count)} />
      </td>
      <td>
        <div className="d-flex justify-content-between">
          {(n * price * vat).toFixed(2)}
          <span
            className="pointer"
            onClick={handleTrashClick}
            aria-hidden="true"
            role="img"
            aria-label="x"
          >
            &#x274C;
          </span>
        </div>
      </td>
    </tr>
  )
}

const SumRows = (props) => {
  useEffect(() => {
    props.setTotall(Number(total.toFixed(2)))
  }, [props.rows])

  const text = props.text
  const rows = props.rows
  const VATpercent = props.VATpercent
  const vat = 1 + VATpercent / 100

  var total = 0
  for (var i = 0; i < rows.length; i++) {
    total += rows[i].price * rows[i].count
  }

  return (
    <tr>
      <td className="align-middle text-end">Итого за {text}:</td>
      <td className="td_important">{total.toFixed(2)}</td>
      <td colSpan="3" className="align-middle text-end">
        рублей в том числе НДС:
      </td>
      <td className="td_important">{(total * vat).toFixed(2)}</td>
    </tr>
  )
}

const MaterialRow = (props) => {
  const m_VATpercent = props.m_VATpercent
  const vat = 1 + m_VATpercent / 100

  const dispatch = useDispatch()

  const [price, setPriceState] = useState(0)
  const [m_row, m_rowSetChange] = useState(props.material_row) //PROBABLY NOT CORRECT!

  const selectPriceById = (state, todoId) => {
    return state.price.filter(record => record.item_id === todoId).map(item => item.price)
  }
  const price_reduxStore = useSelector(state => selectPriceById(state, m_row.item_id), shallowEqual)

  useEffect(() => {
    if (price_reduxStore?.length) {
      //const result = Number(record)
      setPriceState(Number(price_reduxStore))

      m_rowSetChange((prevState) => {
        const newState = {
          ...prevState,
          price: Number(price_reduxStore)
        }
        props.editMaterialRow(newState)
        return newState
      })
    }
  }, [price_reduxStore])

  const [n, setN] = useState(0)

  const [updateMaterial] = useUpdateMaterialMutation()

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetMaterialsQuery('materialsList', {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  let materials

  if (isLoading) materials = <PulseLoader color={"#FFF"} />
 
  if (isSuccess) {
    const { ids, entities } = data
    const filteredIds = [...ids]
    materials = filteredIds.map(id => data?.entities[id])
    //const materials = data.sort((a, b) => a.index - b.index); // b - a for reverse sort
  }

  const updateCount = e => {
    setN(e)
    const newState = Object.assign(m_row, {
      count: e
    })
    m_rowSetChange(newState)
    props.editMaterialRow(newState)
  }

  const updatePrice = e => {

    const init_price = materials
      .filter(material => material._id === e.target.value)
      .map(filteredMaterial => filteredMaterial.price)[0]

    const selected_item = materials
      .filter(material => material._id === e.target.value)
      .map(filteredMaterial => filteredMaterial.item)[0]

    const selected_measure = materials
      .filter(material => material._id === e.target.value)
      .map(filteredMaterial => filteredMaterial.measure)[0]

    init_price ? setPriceState(init_price) : setPriceState(0)

    const newState = Object.assign(m_row, {
      item_id: e.target.value,
      item: String(selected_item),
      measure: String(selected_measure),
      price: init_price ? init_price : 0
    })
    m_rowSetChange(newState)
    props.editMaterialRow(newState)
    //console.log(e.target.value)
  }

  const updateOnlyPrice = async e => {////////should be changed!!!!!!
    setPriceState(Number(e.target.value))
    await updateMaterial({ id: m_row.item_id, price: Number(e.target.value) })

    m_rowSetChange((prevState) => {
      const newState = {
        ...prevState,
        price: Number(e.target.value)
      }
      props.editMaterialRow(newState)
      return newState
    })

    dispatch(setPrice([{ item_id: String(m_row.item_id), price: Number(e.target.value) }]))
  }

  const handleTrashClick = () => {
    props.onTrashClickMaterial(m_row.id)
  }

  return (
    <tr>
      <td>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <select onChange={updatePrice} defaultValue="">
                <option></option>
                {materials?.length ? materials.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.item + ", " + option.measure}
                  </option>
                )) : ""}
              </select>
            </div>
          </div>
        </div>
      </td>
      <td>
        <input
          className="inrow_material_price"
          value={price}
          onChange={updateOnlyPrice}
        />
      </td>
      <td>{(price * (vat - 1)).toFixed(2)}</td>
      <td>{(price * vat).toFixed(2)}</td>
      <td>
        <InputSpinner step={0.5} onChange={count => updateCount(count)} />
      </td>
      <td>
        <div className="d-flex justify-content-between">
          {(n * price * vat).toFixed(2)}
          <span
            className="pointer"
            onClick={handleTrashClick}
            aria-hidden="true"
            role="img"
            aria-label="x"
          >
            &#x274C;
          </span>
        </div>
      </td>
    </tr>
  )
}

const TotalSum = (props) => {
  const [totallS, setTotallService] = useState(0)
  const [totallM, setTotallMaterial] = useState(0)

  const sVATpercent = props.sVATpercent
  const mVATpercent = props.mVATpercent
  const svat = 1 + sVATpercent / 100
  const mvat = 1 + mVATpercent / 100

  useEffect(() => {
    setTotallService(props.totallS)
  }, [props.totallS])

  useEffect(() => {
    setTotallMaterial(props.totallM)
  }, [props.totallM])

  return (
    <tr>
      <th scope="col" className="align-middle text-end">
        ИТОГО по Акту-квит.:
      </th>
      <th scope="col" className="td_very_important align-middle text-center">
        {(totallM + totallS).toFixed(2)}
      </th>
      <th colSpan="3" scope="col" className="align-middle text-end">
        рублей в том числе НДС:
      </th>
      <th className="td_very_important align-middle text-center">
        {(totallM * mvat + totallS * svat).toFixed(2)}
      </th>
    </tr>
  )
}

export default Invoice
//lsof -i tcp:3000
//kill -9 15640

//mistakes and tasks

//-
//added material then made invoice with that material and got the error:
//react-jsx-dev-runtime.development.js:87 Warning: Each child in a list should have a unique "key" prop.
//+

//-
//material list doesn't refresh live after canceling invoice
//+

//-
//If I'm adding new invoice with initial choise (i.e. empty materials and zero value) then cannot cancel it, getting error code 400
//+

//-
//When adding a price to newly added material row with initial choise (i.e. empty materials and zero value), getting error: PUT http://localhost:3500/invoices/materials/ 404 (Not Found)
//


import { useState } from "react"
import SearchBar from "./SearchBar"

const TableHead = ({ columns, handleSorting, tableData, setSearchResults }) => {

    const [sortField, setSortField] = useState("")
    const [order, setOrder] = useState("asc")

    const handleSortingChange = (accessor) => {
        const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc"
        setSortField(accessor)
        setOrder(sortOrder)
        handleSorting(accessor, sortOrder)
    }

    return (
        <thead className="table__thead">
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const cl = sortable
                        ? sortField === accessor && order === "asc"
                            ? "up"
                            : sortField === accessor && order === "desc"
                                ? "down"
                                : "default"
                        : ""
                    return (
                        <th key={accessor}>
                            <div className="row search__header">
                                <div onClick={sortable ? () => handleSortingChange(accessor) : null} className={`col box1 search_th ${cl}`}>{label}</div>
                                <div className="col box2">{(label === "User") ? <SearchBar tableData={tableData} setSearchResults={setSearchResults} /> : ""}</div>
                            </div>
                        </th>
                    )
                })}
                <th></th>
            </tr>
        </thead>
    )
}

export default TableHead
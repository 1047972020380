import { useParams } from 'react-router-dom'
import EditProfileForm from './EditProfileForm'
import { useGetUsersQuery } from '../users/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditProfile = () => {
    useTitle('Edit Profile')

    const { id } = useParams()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[id]
        }),
        refetchOnFocus: true,//not needed, has no effect
        refetchOnMountOrArgChange: true//not needed, has no effect
    })

    if (!user) return <PulseLoader color={"#FFF"} />

    const content = <EditProfileForm user={user} />

    return content
}
export default EditProfile
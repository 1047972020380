import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from "../hooks/useAuth"
import { useGetUsersQuery } from '../features/users/usersApiSlice'

const DashFooter = () => {

    const { userId, status } = useAuth()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
        //refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let username

    if (user) {
        //console.dir(user)
        //console.log("useruseruser")
        const getUppercases = () => {
            const firstname = user.firstname ? user.firstname?.charAt(0).toUpperCase() + "." : ""
            const middlename = user.middlename ? user.middlename.charAt(0).toUpperCase() + "." : ""
            return firstname + middlename
        }
        const initials = getUppercases()
        username = user.surname + " " + initials
    }

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const onGoHomeClicked = () => navigate('/dash')

    let goHomeButton = null
    if (pathname !== '/dash') {
        goHomeButton = (
            <button
                className="dash-footer__button icon-button"
                title="Home"
                onClick={onGoHomeClicked}
            >
                <FontAwesomeIcon icon={faHouse} />
            </button>
        )
    }

    const content = (
        <footer className="dash-footer">
            {goHomeButton}
            <p>Current User: {username}</p>
            <p>Status: {status}</p>
        </footer>
    )
    return content
}
export default DashFooter
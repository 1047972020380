import { apiSlice } from "../../app/api/apiSlice"
import { logOut, setCredentials } from "./authSlice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    //console.log(data)
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    //console.log(data)
                    const { accessToken } = data
                    dispatch(setCredentials({ accessToken }))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        forgotpassword: builder.mutation({
            query: credentials => ({
                url: '/auth/forgotpassword',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        resetpassword: builder.mutation({
            query: credentials => ({
                url: '/auth/resetpassword',
                method: 'PATCH',
                body: {
                    ...credentials,
                }
            })
        }),
        resetpwdotp: builder.mutation({
            query: credentials => ({
                url: '/auth/resetpwdotp',
                method: 'PATCH',
                body: {
                    ...credentials,
                }
            })
        }),
        generateotpsms: builder.mutation({
            query: credentials => ({
                url: '/auth/generateotpsms',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        loginStep2: builder.mutation({
            query: credentials => ({
                url: '/auth/login-step2',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        generate2FaSecret: builder.mutation({
            query: credentials => ({
                url: '/auth/generate-2fa-secret',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        verifyOtp: builder.mutation({
            query: credentials => ({
                url: '/auth/verify-otp',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        disable2Fa: builder.mutation({
            query: credentials => ({
                url: '/auth/disable-2fa',
                method: 'POST',
                body: { ...credentials }
            })
        }),
    })
})

export const {
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useForgotpasswordMutation,
    useResetpasswordMutation,
    useResetpwdotpMutation,
    useGenerateotpsmsMutation,
    useLoginStep2Mutation,
    useGenerate2FaSecretMutation,
    useVerifyOtpMutation,
    useDisable2FaMutation,
} = authApiSlice 
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './weather.css'
//import './index.js'

//weather forecast
const FWeather = () => {
    const iconStyle = {
        width: "30px",
        height: "30px"
    }

    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=52.2138&lon=24.3564&appid=7bceb8626cd8e2e8d4ab9505b44c82bd&units=metric&lang=ru`

    const [data, setData] = useState({})

    useEffect(() => {
        axios.get(url).then((response) => {
            setData(response.data)
            //console.log(response.data)
        })
    }, [])

    const days_data = data.list?.filter((x) => new Date(x.dt * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) == "11:00" ||
        new Date(x.dt * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) == "20:00").map(day => (
            <WeatherRow
                key={day.dt}
                date={day.dt}
                temp={day.main.temp}
                humidity={day.main.humidity}
                icon={day.weather ? day.weather[0].icon : null}
                wind={day.wind.speed}
                time={new Date(day.dt * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
            />
        ))

    return (
        <table className="table table-sm table-hover weatherTable">
            <thead>
                <tr className="text-center">
                    <th scope="col" style={{width:"30%"}}><img src="date.png" style={iconStyle} /></th>
                    <th scope="col" style={{width:"15%"}}><img src="temperature.png" style={iconStyle} /></th>
                    <th scope="col" style={{width:"15%"}}><img src="humidity.png" style={iconStyle} /></th>
                    <th scope="col" style={{width:"15%"}}><img src="windsock.png" style={iconStyle} /></th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {days_data}
            </tbody>
        </table>
    )
}

const WeatherRow = (props) => {

    const iconStyle = {
        width: "42px",
        height: "42px"
    }

    const date = new Date(props.date * 1000).toLocaleString('ru-RU', { weekday: 'short', day: 'numeric', month: 'long', /*year: 'numeric', hour: '2-digit', minute: '2-digit'*/ })

    const iconUrl = `https://openweathermap.org/img/wn/${props.icon}.png`

    const eveningTime = (props.time == "20:00") ? 'night' : 'day'

    return (
        <tr className={`${eveningTime}`}>
            <td>
                {(props.time == "20:00") ? <span>Вечер<img src="moon1.png" style={iconStyle} /></span> : <span>День<img src="sunny.png" style={iconStyle} /></span>}{date}
            </td>
            <td>
                {Math.round(props.temp) /** 10)/10*/}°C<img src={iconUrl} />
            </td>
            <td>
                {Math.round(props.humidity / 5) * 5}%
            </td>
            <td>
                {Math.round(props.wind) /** 10)/10*/}
            </td>
        </tr>
    )
}

export default FWeather

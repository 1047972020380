import { useState, useEffect } from "react"
import useAuth from "../../hooks/useAuth"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import {
  useGenerate2FaSecretMutation,
  useVerifyOtpMutation,
  useDisable2FaMutation
} from './authApiSlice'
import OtpInput from "./OtpInput"
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch } from 'react-redux'
import { setOtpSms } from '../settings/authSettsSlice'

const Enable2fa = ({ twofaEnabled /*, disable2FaSuccess, verifyOtpSuccess*/ }) => {

  const { email } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    setSwitch2fa(twofaEnabled)
  }, [twofaEnabled])

  //const [otp, setOtp] = useState(null)
  const [secret, setSecret] = useState(null)
  //const [message, setMessage] = useState(null)

  const [showEnable2fa, setShowEnable2fa] = useState(false)
  const [switch2fa, setSwitch2fa] = useState(twofaEnabled)


  const [generate2FaSecret, {
    //data: gen2FaSecretdata,
    //isLoading: isGen2FaSecretLoading,
    //isSuccess: isGen2FaSecretSuccess,
    //isError: isGen2FaSecretError,
    //error: gen2FaSecreterror
  }] = useGenerate2FaSecretMutation()

  const [verifyOtp, {
    //data: verifyOtpdata,
    //isLoading: isVerifyOtpLoading,
    isSuccess: isVerifyOtpSuccess,
    //isError: isVerifyOtpError,
    //error: verifyOtperror
  }] = useVerifyOtpMutation()

  /*useEffect(() => {
    if (isVerifyOtpSuccess) {
      verifyOtpSuccess(isVerifyOtpSuccess)
    }
  }, [isVerifyOtpSuccess])*/

  const [disable2Fa, {
    //data: disable2Fadata,
    //isLoading: isDisable2FaLoading,
    isSuccess: isDisable2FaSuccess,
    //isError: isDisable2FaError,
    //error: disable2Faerror
  }] = useDisable2FaMutation()

  /*useEffect(() => {
    if (isDisable2FaSuccess) {
      disable2FaSuccess(isDisable2FaSuccess)
    }
  }, [isDisable2FaSuccess])*/

  const handleInfo = (message) =>
    toast.info(message, {
      position: "top-center",
      transition: Slide,
      autoClose: 4000,
      //onClose: () => navigate('/auth/login-step2')
    })

  const handleError = (err) =>
    toast.error(err, {
      position: "top-center",
      transition: Slide,
      autoClose: 4000
    })

  const onOtpSubmit = async (otp) => {
    try {
      const { message } = await verifyOtp({ email, token: otp }).unwrap()
      setShowEnable2fa(false)
      setSwitch2fa(true)
      dispatch(setOtpSms(false))
      handleInfo(message)
    } catch (err) {
      if (!err.status) {
        handleError('No Server Response')
      } else if (err.status === 400) {
        handleError(err.data?.message)
      } else {
        handleError(err.data?.message)
      }
    }
  }

  const change2FA = async (event) => {
    event.preventDefault()
    if (event.target.checked) {
      setShowEnable2fa(event.target.checked)
      try {
        const { secret, qrImageDataUrl/*, message*/ } = await generate2FaSecret({ email }).unwrap()
        setSecret({ secret, qrImageDataUrl })
      } catch (err) {
        if (!err.status) {
          handleError('No Server Response')
        } else if (err.status === 400) {
          handleError(err.data?.message)
        } else {
          handleError(err.data?.message)
        }
      }
    } else {
      try {
        const { message } = await disable2Fa({ email }).unwrap()
        setSwitch2fa(false)
        dispatch(setOtpSms(true))
        handleInfo(message)
      } catch (err) {
        if (!err.status) {
          handleError('No Server Response')
        } else if (err.status === 400) {
          handleError(err.data?.message)
        } else {
          handleError(err.data?.message)
        }
      }
      /*
      axios
        .post(
          "http://localhost:3500/auth/disable-2fa",
          { email },
          {}
        )
        .then((response) => {
          if (response.status === 200 && !response.data.twofaEnabled) {
            setSwitch2fa(false)
            setMessage(null)
          }
        })
        .catch((error) => {
          setMessage("Error")
        })
        */
    }
  }

  const alertsBorderClass = twofaEnabled ? '' : 'alerts-border'

  return (
    <>
      <div className={`form-check form-switch ${alertsBorderClass} m-1`}>
        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={switch2fa} onChange={change2FA} />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">TOTP 2FA</label>
      </div>
      {secret && (
        <>
          <Modal
            show={showEnable2fa}
            onHide={() => setShowEnable2fa(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>Enable 2FA</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="twofa_instructions">
                Scan the following QR code or manually input the secret in{" "}
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                >
                  Google Authenticator
                </a>{" "}
                and then enter an OTP below to enable 2-Factor Authentication
              </div>
              <div className="text-center twofa_secret">
                <img src={secret.qrImageDataUrl} />

                <p>
                  Secret: <b>{secret.secret}</b>
                </p>
              </div>
              <div className="text-center">
                <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col text-start">
                <Button variant="secondary" onClick={() => setShowEnable2fa(false)}>
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <ToastContainer />
    </>
  )
}

export default Enable2fa

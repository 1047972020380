import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"
import PulseLoader from 'react-spinners/PulseLoader'
import { Navigate } from "react-router-dom"

const Require2faJWT = ({ element }) => {

    const token = useSelector(selectCurrentToken)

    return token ? (
        element
      ) : (
        <Navigate to="/login" replace={true} />
      )
}
export default Require2faJWT
import React, { useState } from 'react'

const Ctime = () => {

  let time = new Date().toLocaleTimeString('ru-RU')

  const [ctime, setTime] = useState(time)
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString('ru-RU')
    setTime(time)
  }
  setInterval(UpdateTime)

  return (
    <>{ctime}</>
  )
}

export default Ctime